import axios from 'services/axios'

const searchCompanies = (
	name?: string,
	location?: string,
	type?: string,
	currentPage?: number
): Promise<SearchResult[]> => {
	const queryParams = new URLSearchParams()

	queryParams.append('page', currentPage?.toString() ?? '1')
	queryParams.append('limit', '10')
	queryParams.append('name', name || '')
	queryParams.append('country', location || '')

	if (type) {
		queryParams.append('type', type)
	}

	const queryString = queryParams.toString()

	return axios.get(`/company?${queryString}`).then(response => response.data.data)
}

const getCompanyById = (id: string): Promise<Company> => {
	return axios.get(`/company/${id}`).then(response => response.data)
}

const getSearchSuggestions = (name: string): Promise<SearchSuggestions> => {
	const queryParams = new URLSearchParams()
	queryParams.append('page', '1')
	queryParams.append('limit', '10')
	queryParams.append('name', name || '')

	const queryString = queryParams.toString()

	return axios
		.get(`/searchCompany?${queryString}`)
		.then(response => response.data)
		.catch(err => err)
}

const getCompanyLogo = (id: string): Promise<{ name: string; url: string }[]> => {
	return axios.get(`/logo/company?company_name=${id}`).then(response => response.data)
}

const getCompanySlider = (id: string): Promise<{ name: string; url: string }[]> => {
	return axios.get(`/slider/company?company_name=${id}`).then(response => response.data)
}

const getCompanyFaqs = (id: string): Promise<Faq[]> => {
	return axios.get(`/company/faqs/${id}`).then(response => response.data)
}

const getSchedule = (id: string): Promise<Schedule[]> => {
	return axios.get(`/company/scedule/${id}`).then(response => response.data)
}

const companyService = {
	searchCompanies,
	getSearchSuggestions,
	getSchedule,
	getCompanySlider,
	getCompanyById,
	getCompanyFaqs,
	getCompanyLogo
}

export default companyService
