import React from 'react'

import clsx from 'clsx'
import { AppHeader, DashboardHeader } from 'components/app/header'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

type NavItem = {
	name: string
	icon?: JSX.Element
	href: string
	current?: boolean
}

interface AppLayoutProps {
	children: React.ReactNode
	title?: string
	onLogoClick?: () => void
	renderHeader?: boolean
	isChat?: boolean
	renderDashboardHeader?: boolean
	navigation?: NavItem[]
	className?: string
	wrapperClassName?: string
}

export const AppLayout: React.FC<AppLayoutProps> = ({
	renderHeader = false,
	renderDashboardHeader = false,
	onLogoClick,
	children,
	navigation,
	className,
	wrapperClassName,
	title
}) => {
	const docTitleText = title
		? `${title} | HealthCorner`
		: 'Health Corner - Online Termine bei Gesundheitsbetrieben und Fachpersonen'

	useDocumentTitle(docTitleText)

	return (
		<div className={clsx('relative', className)}>
			{renderHeader ? <AppHeader onLogoClick={onLogoClick} navigationItems={navigation} /> : null}
			{renderDashboardHeader ? <DashboardHeader navigationItems={navigation} /> : null}
			<div className={clsx(wrapperClassName, { 'mt-20': renderDashboardHeader })}>{children}</div>
		</div>
	)
}
