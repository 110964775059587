import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Button } from 'components/app/button'
import { AppLayout } from 'components/app/layout'
import { LabelInput } from 'components/inputs/input'
import userService from 'services/user-service'
import { getTKey } from 'utils/language'

export const ChangePassword = () => {
	const { t } = useTranslation()
	const tKey = getTKey('auth')

	const schema = yup.object({
		oldPassword: yup.string().required(t(tKey('errors.oldPassword'))),
		newPassword: yup
			.string()
			.required(t(tKey('errors.newPassword')))
			.matches(/^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/, t('auth.errors.validPassword'))
			.min(8, t(tKey('errors.minPassword'))),
		confirmPassword: yup
			.string()
			.required(t(tKey('errors.confirmPassword')))
			.oneOf([yup.ref('newPassword'), ''], t(tKey('errors.matchPassword')))
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<any>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const onSubmit = (data: any) => {
		userService
			.updateUserPassword(data.oldPassword, data.newPassword)
			.then(() => {
				toast.success(t(tKey('toast.passwordSuccess')))
				reset()
			})
			.catch(err => toast.error(t(tKey('toast.invalidPassword'))))
	}

	return (
		<AppLayout renderDashboardHeader>
			<div className="py-8 px-6 lg:px-[220px] 2xl:px-[400px] flex flex-col gap-y-3.5">
				<h1 className="text-primary text-sm max-lg:text-center lg:text-xl font-bold py-[15px] lg:pb-8 lg:border-b border-[#D3E3F1]">
					{t(tKey('changePassword.privacySettings'))}
				</h1>
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-5 py-4">
					<h3 className="lg:text-lg text-sm text-primary font-bold">
						{t(tKey('changePassword.title'))}
					</h3>
					<div className="flex flex-col gap-y-5 lg:gap-y-6">
						<LabelInput
							register={register}
							errors={errors}
							type="password"
							name="oldPassword"
							labelText={t(tKey('changePassword.labels.oldPassword'))}
						/>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 gap-x-5">
							<LabelInput
								register={register}
								errors={errors}
								type="password"
								name="newPassword"
								labelText={t(tKey('changePassword.labels.newPassword'))}
							/>
							<LabelInput
								register={register}
								errors={errors}
								type="password"
								name="confirmPassword"
								labelText={t(tKey('changePassword.labels.reEnterPassword'))}
							/>
						</div>
					</div>
					<Button className="lg:w-fit lg:ml-auto">{t(tKey('changePassword.labels.save'))}</Button>
				</form>
			</div>
		</AppLayout>
	)
}
