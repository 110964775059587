import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { Control, Controller, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import { Link, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import { InputCalendarIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Highlights } from 'components/auth/highlights'
import { Checkbox } from 'components/inputs/checkbox'
import { LabelInput } from 'components/inputs/input'
import { RadioInput } from 'components/inputs/radio'
import { AuthOptions } from 'constants/constants'
import { getTKey } from 'utils/language'

interface RegisterProps {
	register?: UseFormRegister<any>
	errors?: FieldErrors<any>
	control?: Control<any, any>
	watch?: UseFormWatch<any>
	isLoading: boolean
	showSubmitButton?: boolean
	showHighlights?: boolean
}

export const Register: React.FC<RegisterProps> = ({
	register,
	errors,
	watch,
	control,
	isLoading,
	showSubmitButton = true,
	showHighlights = true
}) => {
	const { t } = useTranslation()
	const tKey = getTKey('auth')
	const [searchParams, setSearchParams] = useSearchParams()

	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)

	const agreement = watch?.('agreement')

	return (
		<div>
			<h2 className="font-domine text-[#123258] lg:text-3xl text-[22px] font-bold leading-10 pb-2 max-lg:text-center">
				{t(tKey('register.title.signUp'))}
			</h2>
			<p className="text-secondary font-nunito text-base font-normal leading-6 pb-4 2xl:pb-8 max-lg:text-center">
				{t(tKey('register.title.createYourAccount'))}
			</p>
			<div className="max-lg:px-5">
				<div className="flex flex-col gap-y-5">
					<LabelInput
						type="text"
						name="fname"
						register={register}
						id="fname"
						labelText={t(tKey('register.labels.firstName'))}
						errors={errors}
					/>

					<LabelInput
						type="text"
						register={register}
						name="lname"
						labelText={t(tKey('register.labels.lastName'))}
						errors={errors}
					/>
					<LabelInput
						labelText={t(tKey('register.labels.email'))}
						name="email"
						register={register}
						errors={errors}
					/>
					<Controller
						control={control}
						name="gender"
						render={({ field: { onChange, value } }) => (
							<div className="flex flex-col gap-y-1">
								<h3 className="text-primary text-sm">{t(tKey('register.labels.gender'))}</h3>
								<div className="flex flex-row gap-x-3">
									<RadioInput
										labelText={t(tKey('register.labels.male'))}
										onChange={onChange}
										name="gender"
										register={register}
										value="male"
										checked={value === 'male'}
									/>
									<RadioInput
										labelText={t(tKey('register.labels.female'))}
										onChange={onChange}
										register={register}
										name="gender"
										value="female"
										checked={value === 'female'}
									/>
								</div>
								{errors?.gender && (
									<p className="text-xs text-red-500 mt-1">{errors.gender.message as string}</p>
								)}
							</div>
						)}
					/>

					<Controller
						control={control}
						name={'phonenumber'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<PhoneInput
									numberInputProps={{
										className:
											'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
									}}
									placeholder={t(tKey('register.placeholder.labels.enterYourPhone'))}
									defaultCountry="CH"
									value={value ?? '+41'}
									error={error}
									onChange={onChange}
								/>
								{errors?.phonenumber && (
									<p className="text-xs text-red-500 mt-1">
										{errors.phonenumber.message as string}
									</p>
								)}
							</div>
						)}
					/>

					<div className="relative z-20 flex items-center">
						<LabelInput
							name="birthDate"
							register={register}
							errors={errors}
							type="date"
							labelText={t(tKey('register.labels.dob'))}
						/>
						<div
							className={clsx('absolute -z-20 right-3', {
								'-translate-y-2.5': errors?.birthDate
							})}>
							<img src={InputCalendarIcon} />
						</div>
					</div>

					<div className="relative z-20 flex items-center">
						<LabelInput
							type={isPasswordVisible ? 'text' : 'password'}
							register={register}
							errors={errors}
							name="password"
							labelText={t(tKey('register.labels.password'))}
						/>
						<div
							onClick={() => setIsPasswordVisible(!isPasswordVisible)}
							className={clsx('absolute right-4', {
								'-translate-y-2.5': errors?.password
							})}>
							{isPasswordVisible ? (
								<EyeIcon className="w-6 h-6 stroke-primary" />
							) : (
								<EyeSlashIcon className="w-6 h-6 stroke-primary" />
							)}
						</div>
					</div>
					<div className="relative z-20 flex items-center">
						<LabelInput
							type={isConfirmPasswordVisible ? 'text' : 'password'}
							register={register}
							errors={errors}
							name="confirmPassword"
							labelText={t(tKey('register.labels.confirmPassword'))}
						/>
						<div
							onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
							className={clsx('absolute right-4', {
								'-translate-y-2.5': errors?.confirmPassword
							})}>
							{isConfirmPasswordVisible ? (
								<EyeIcon className="w-6 h-6 stroke-primary" />
							) : (
								<EyeSlashIcon className="w-6 h-6 stroke-primary" />
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-y-1">
					<div className="flex items-center justify-between gap-x-4 text-sm pt-5">
						<div className="text-sm gap-x-2 flex items-center ">
							<Controller
								name="agreement"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Checkbox
										name="agreement"
										value="agree"
										checked={value === 'agree'}
										onChange={e => (agreement ? onChange('') : onChange(e.target.value))}
									/>
								)}
							/>
							<label className="font-medium text-secondary xl:whitespace-nowrap">
								{t(tKey('register.labels.agree'))}{' '}
								<Link
									className="cursor-pointer underline text-primary inline-block"
									to="/datenschutzzentrum">
									{t(tKey('register.labels.terms'))}
								</Link>
								<span className="font-medium text-secondary ml-1">
									{t(tKey('register.labels.and'))}{' '}
								</span>
								<Link
									className="cursor-pointer underline text-primary"
									target="__blank"
									to="/datenschutzzentrum">
									{t(tKey('register.labels.privacyPolicy'))}
								</Link>
							</label>
						</div>
					</div>
					{errors?.agreement && (
						<p className="text-xs text-red-500">{errors.agreement.message as string}</p>
					)}
				</div>

				{showSubmitButton && (
					<div className="flex justify-center my-6">
						<Button
							className="bg-primary text-white w-3/5 py-3 px-0 capitalize text-sm"
							type="submit"
							disabled={isLoading}>
							{isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t(tKey('register.labels.pleaseWait'))}
									</span>
								</div>
							) : (
								<span>{t(tKey('register.labels.signUp'))}</span>
							)}
						</Button>
					</div>
				)}
				{showSubmitButton && (
					<div className="flex items-center justify-center gap-x-4 text-sm">
						<div className="text-sm text-secondary flex items-center ">
							{t(tKey('register.labels.alreadyHaveAnAccount'))}
							<span
								onClick={() => setSearchParams({ tab: AuthOptions.LOGIN })}
								className="cursor-pointer underline text-primary ml-1 underline-offset-4">
								{t(tKey('register.labels.signIn'))}
							</span>
						</div>
					</div>
				)}
			</div>
			{showHighlights && (
				<div className="max-lg:pt-8">
					<Highlights />
				</div>
			)}
		</div>
	)
}
