import axios from 'services/axios'

const getTopicById = (id: string): Promise<Topic> => {
	return axios.get(`/topic/${id}`).then(response => response.data)
}

const getTopicsByArticle = (id: string): Promise<Topic[]> => {
	return axios.get(`/topics/${id}`).then(response => response.data)
}

const createTopic = (data: TopicForm) => {
	return axios.post('/topic', data).then(response => response.data)
}

const updateTopic = (id: string, data: TopicForm) => {
	return axios.put(`/topic/${id}`, data).then(response => response.data)
}

const deleteTopic = (id: string) => {
	return axios.delete(`/topic/${id}`).then(response => response.data)
}

const topicService = {
	getTopicById,
	getTopicsByArticle,
	createTopic,
	updateTopic,
	deleteTopic
}

export default topicService
