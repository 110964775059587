import clsx from 'clsx'
import useIsMobile from 'hooks/useIsMobile'
import { useTranslation } from 'react-i18next'
import { getTKey } from 'utils/language'

type SearchContent = {
	companies: { name: string }[]
	companyTypes: { name: string }[]
	services: { name: string }[]
}
interface SearchSuggestionProps {
	content: SearchContent
	onSelection: (value: string) => void
	className?: string
}

export const SearchSuggestion = ({ content, onSelection, className }: SearchSuggestionProps) => {
	const isMobile = useIsMobile()
	const { t } = useTranslation()
	const tKey = getTKey('search')
	return (
		<div
			className={clsx(
				'absolute z-20 lg:translate-y-[70px] w-full grid grid-cols-3 max-h-[280px] overflow-y-auto rounded-lg main-search-shadow border border-[#12325833] bg-white text-primary',
				className
			)}>
			<div className="flex px-3 flex-col gap-y-1 border-r border-gray-300">
				<h2 className="max-lg:text-sm py-3 font-bold border-b bg-white sticky top-0 border-gray-300 pb-1">
					{t(tKey('companies'))}
				</h2>
				{content.companies?.map(company => (
					<div
						key={company.name}
						onClick={() => onSelection(company.name)}
						className="flex cursor-pointer hover:bg-[#e0f2f0b3] px-1 py-2 rounded flex-col text-xs lg:text-sm text-secondary overflow-y-auto">
						{company.name}
					</div>
				))}
			</div>
			<div className="flex flex-col px-3 gap-y-1 border-r border-gray-300">
				<h2 className="max-lg:text-sm py-3 font-bold border-b bg-white sticky top-0 border-gray-300 pb-1">
					{isMobile ? t(tKey('types')) : t(tKey('companyTypes'))}
				</h2>
				{content.companyTypes?.map(type => (
					<div
						key={type.name}
						onClick={() => onSelection(type.name)}
						className="flex cursor-pointer hover:bg-[#e0f2f0b3] px-4 py-2 rounded flex-col text-xs lg:text-sm text-secondary overflow-y-auto">
						{type.name}
					</div>
				))}
			</div>
			<div className="flex flex-col px-3 gap-y-1">
				<h2 className="max-lg:text-sm py-3 font-bold border-b bg-white sticky top-0 border-gray-300 pb-1">
					{t(tKey('services'))}
				</h2>
				{content.services?.map(service => (
					<div
						key={service.name}
						onClick={() => onSelection(service.name)}
						className="flex cursor-pointer hover:bg-[#e0f2f0b3] px-1 py-2 rounded flex-col text-xs lg:text-sm text-secondary overflow-y-auto">
						{service.name}
					</div>
				))}
			</div>
		</div>
	)
}
