import { createContext, useContext, useState } from 'react';

interface SearchContext {
	searchResult: SearchResult[]
	searchParams: { name: string; country: string }
	storeSearchResult: (value: SearchResult[]) => void
	storeSearchParams: (name: string, country: string) => void
}

const SearchContext = createContext<SearchContext | null>(null)

const SearchProvider = ({ children }: { children: React.ReactNode }) => {
	const [searchResult, setSearchResult] = useState<SearchResult[]>([] as SearchResult[])
	const [searchParams, setSearchParams] = useState({ name: '', country: '' })

	const storeSearchResult = (result: SearchResult[]) => {
		setSearchResult(result)
	}

	const storeSearchParams = (name: string, country: string) => {
		setSearchParams({ name, country })
	}

	return (
		<SearchContext.Provider
			value={{ searchResult, searchParams, storeSearchResult, storeSearchParams }}>
			{children}
		</SearchContext.Provider>
	)
}

const useSearchContext = () => {
	const searchContext = useContext(SearchContext)

	if (!searchContext) {
		throw new Error('search context has to be used within <SearchContext.Provider>')
	}

	return searchContext
}

export { SearchProvider, useSearchContext };

