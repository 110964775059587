export enum Language {
	EN = 'en',
	DE = 'de'
}

export enum AuthOptions {
	LOGIN = 'Log In',
	REGISTER = 'Register',
	FORGOT = 'Forgot Password',
	RESET = 'Reset Password',
	OTP = 'Otp'
}

export enum AppointmentStatus {
	COMPLETED = 'abschluss',
	CONFIRMED = 'bearbeitung',
	PENDING = 'buchung_termin',
	CANCELLED = 'canceled',
	CHECKIN = 'check_in',
	PENDENT = 'pendent'
}

export const mappedStatuses = {
	[AppointmentStatus.COMPLETED]: 'Abgeschlossen',
	[AppointmentStatus.CONFIRMED]: 'In Bearbeitung',
	[AppointmentStatus.PENDING]: 'Termin gebucht',
	[AppointmentStatus.CANCELLED]: 'Storniert',
	[AppointmentStatus.CHECKIN]: 'Check-In',
	[AppointmentStatus.PENDENT]: 'Pendent'
}

export const alphabets = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z'
]

export const RE_DIGIT = new RegExp(/^\d+$/)
