import { useParams } from 'react-router'

import clsx from 'clsx'

import { Breadcrumb } from 'components/app/breadcrumb'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { dataCenterContent, dataCenterDetails } from 'pages/datacenter/data'

export const DataCenterDetails = () => {
	const params = useParams()
	const title = params.page as string
	const isMobile = useIsMobile()

	const auth = useAppSelector(state => state.auth)

	const selectedDataCenter = dataCenterContent.find(content => content.subMenu?.includes(title))

	const scrollToElement = (id: string) => {
		const element = document.getElementById(id)
		if (element) {
			const offset = 20
			const bodyRect = document.body.getBoundingClientRect().top
			const elementRect = element.getBoundingClientRect().top
			const elementPosition = elementRect - bodyRect
			const offsetPosition = elementPosition - offset

			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			})
		}
	}

	return (
		<AppLayout renderHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
			<div
				className={clsx(
					'bg-datacenterDetails bg-no-repeat lg:bg-center bg-cover h-[193px] lg:h-[283px] w-full px-5 lg:px-[60px] py-[18px]',
					{ datacenterBackground: isMobile }
				)}>
				<Breadcrumb
					options={[
						{ name: 'Datenschutzzentrum', href: '/datenschutzzentrum', current: false },
						{
							name: title,
							href: `/datenschutzzentrum/${title}`,
							current: true
						}
					]}
					theme="dark"
				/>
				<div className="flex flex-col gap-y-6 justify-center w-full h-full">
					<h5 className="text-[#06AB91] font-semibold">{selectedDataCenter?.name}</h5>
					<h1 className="lg:text-[40px] text-2xl font-bold font-domine text-primary">{title}</h1>
				</div>
			</div>
			<div className="flex flex-col lg:flex-row gap-x-14 px-5 lg:px-[60px] pt-[66px] pb-14 lg:pb-[100px]">
				<div className="lg:basis-[75%] basis-full 2xl:basis-[70%] flex flex-col gap-y-8">
					{dataCenterDetails[title]?.details.map((detail, index) => (
						<div id={detail.heading} key={index} className="flex flex-col gap-y-4">
							<div className="flex items-center gap-x-4">
								<h2
									className={clsx('text-primary text-lg font-bold w-full', {
										'font-domine': !detail.content
									})}>
									{detail?.heading}
								</h2>
							</div>
							{detail.content && (
								<p
									className="text-secondary"
									dangerouslySetInnerHTML={{ __html: detail.content }}
								/>
							)}
						</div>
					))}
				</div>

				<div className="h-fit max-lg:hidden lg:basis-[25%] 2xl:basis-[30%] py-6 px-8 bg-[#D3E3F14D] rounded-[22px] flex flex-col gap-y-[13px]">
					<h5 className="text-primary font-bold text-xl">Inhalt</h5>
					{dataCenterDetails[title]?.details.map((detail, index) => (
						<p
							onClick={() => scrollToElement(detail.heading as string)}
							key={index}
							className="text-primary cursor-pointer hover:underline">
							{detail?.heading}
						</p>
					))}
				</div>
			</div>
			<Footer />
		</AppLayout>
	)
}
