import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

import clsx from 'clsx'
import { Language } from 'constants/constants'

interface ListBoxProps {
	options: string[]
	value: string
	onChange: (value: string) => void
	className?: string
}
export const LanguageMenu = ({ value, options, onChange, className }: ListBoxProps) => {
	const handleChange = (value: string) => {
		onChange(value)
	}

	return (
		<div className="w-14 md:w-20">
			<Listbox value={value} onChange={handleChange}>
				<div className="relative">
					<ListboxButton
						className={clsx(
							'relative bg-white flex justify-between items-center w-full cursor-pointer rounded-[100px] border border-[#D3E3F1] search-shadow pl-1.5 md:pl-3 py-1 md:py-2.5 text-center focus:outline-none focus-visible:border-main focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-main-300 sm:text-sm',
							className
						)}>
						<span className="uppercase flex shrink-0 items-center gap-x-2 truncate font-semibold text-secondary text-md">
							{(value === Language.EN || value === 'en-US') && (
								<span className="fi fi-us rounded-full text-lg" />
							)}
							{value === Language.DE && <span className="fi fi-ch rounded-full text-lg" />}
							<span className="text-xs max-md:hidden">{value === 'en-US' ? 'EN' : value}</span>
						</span>
						<span className="absolute grow right-0 translate-x-1 md:translate-x-2 pointer-events-none flex items-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								className="-translate-x-1.5">
								<path d="M15.8077 10.6875H8.19226L12 14.4952L15.8077 10.6875Z" fill="#123258" />
							</svg>
						</span>
					</ListboxButton>

					<ListboxOptions
						anchor="bottom"
						transition
						className="absolute z-50 mt-1 max-h-60 w-max overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{options.map((option, index) => (
							<ListboxOption
								key={index}
								className="relative group data-[selected]:bg-gray-200 text-gray-200 bg-white data-[selected]:text-primary cursor-pointer z-50 select-none py-2 pl-10 font-semibold pr-4 capitalize text-md"
								value={option}>
								<span className="block truncate group-data-[selected]:font-bold font-medium">
									{index === 0 && <span className="fi fi-us rounded-full text-lg" />}
									{index === 1 && <span className="fi fi-ch rounded-full text-lg" />}
								</span>
								<span className="absolute group-data-[selected]:visible invisible inset-y-0 left-0 flex items-center pl-3 text-primary">
									<CheckIcon className="h-5 w-5" aria-hidden="true" />
								</span>
							</ListboxOption>
						))}
					</ListboxOptions>
				</div>
			</Listbox>
		</div>
	)
}
