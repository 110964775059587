import { useTranslation } from 'react-i18next'

import { Breadcrumb } from 'components/app/breadcrumb'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { getTKey } from 'utils/language'

const encyclopediaLinks = [
	'Aeromedical examiner (AME)',
	'Akupressurtherapeut',
	'Akupunkteur',
	'Akupunkturmasseur',
	'Allergologe (Immunologe)',
	'Angiologe',
	'Anthroposophischer Mediziner',
	'Anthroposophischer Therapeut',
	'Anästhesiologe',
	'Arbeitsmediziner',
	'Aromatherapeut',
	'Arzt für Integrative Medizin',
	'Arzt für Verkehrsmedizin',
	'Atemtechniktherapeut',
	'Atlasproaxis Therapeut',
	'Audio-psycho-phonologie Therapeut',
	'Audioprosthologe',
	'Augenarzt',
	'Augenchirurg',
	'Augenoptiker',
	'Aurikulotherapeut',
	'Ayurveda Ernährungstherapeut',
	'Ayurveda Masseur',
	'Ayurveda Pflanzentherapeut',
	'Ayurveda Therapeut',
	'Ayurvedische Medizin Therapeut',
	'Bachblüten-Heilpraktiker',
	'Bewegungstherapeut',
	'Biodynamischer Therapeut',
	'Bioenergie Therapeut',
	'Biofeedback Therapeut',
	'Bioresonanz-Therapeut',
	'Blutspende',
	'Blütentherapeut',
	'COVID-19 Impfzentrum',
	'COVID-19 Test Zentrum',
	'Caycedo Sophrologe',
	'Chiropraktor',
	'Chirurg',
	'Coach',
	'Cranio Sacral-Therapeut',
	'Cranio Sacral-Therapeut - Komplementärtherapeut ED',
	'Darmhydrotherapeut',
	'Dentalhygieniker',
	'Diätberater',
	'Elektrotherapeut',
	'Endodontologe',
	'Endokrinologe (inkl. Diabetesspezialisten)',
	'Energie Rebalancing Therapeut',
	'Energietherapeut',
	'Ergotherapeut',
	'Ernährungsberater',
	'Ernährungswissenschaftler',
	'Etiopath',
	'Facharzt für Allgemeine Innere Medizin',
	'Facharzt für Pharmazeutische Medizin',
	'Facharzt für Schlafmedizin',
	'Facharzt für Suchtmedizin',
	'Fachperson mit Ausbildung in Haptonomie',
	'Fachzahnarzt für Oralchirurgie',
	'Fangotherapeut',
	'Farbtherapeut',
	'Fasciatherapeut',
	'Feldenkrais Therapeut',
	'Fetal-Kardiologe',
	'Gastroenterologe',
	'Gebirgsmediziner',
	'Geburtsbegleiter',
	'Gefässchirurg',
	'Genetiker',
	'Geriater',
	'Gestalttherapeut',
	'Gesundheitsdienstleistungen der Apotheke',
	'Grippeimpfzentrum',
	'Gynäkologe (Frauenarzt und Geburtshelfer)',
	'HNO-Arzt',
	'Handchirurg',
	'Hausarzt (Allgemeinmedizin)',
	'Hautarzt (Dermatologe)',
	'Hebamme',
	'Hepatologe',
	'Hippotherapeut',
	'Homöopath',
	'Hydrotherapeut',
	'Hypnotherapeut (Hypnose)',
	'Hämatologe',
	'Impfzentrum',
	'Impfzentrum Monkeypox/Affenpocken',
	'Infektiologe',
	'Intensivmediziner',
	'Kardiologe',
	'Kardiothorax Chirurg',
	'Kieferorthopäde',
	'Kinder- und Jugendpsychiater',
	'Kinderarzt',
	'Kinderchirurg',
	'Kinderendocrinologe (inkl. Diabetesspezialisten)',
	'Kinderkardiologe',
	'Kinderneurologe',
	'Kinderpneumologe',
	'Kinderpsychologe',
	'Kinderpsychotherapeut',
	'Kinesiologe',
	'Klinikleiter',
	'Krankenschwester/-pfleger',
	'Kryotherapeut',
	'Kräuterheilkundler',
	'Kunsttherapeut',
	'Körperlicher Trainer',
	'Lichttherapeut',
	'Logopäde',
	'Magnettherapeut',
	'Manuelle Lymphdrainage Therapeut',
	'Manueller Mediziner',
	'Masseur (klassische Massage)',
	'Masseur (therapeutische Massage)',
	'Medizinischer Hypnotherapeut (Hypnose)',
	'Medizinischer Masseur (Massage)',
	'Medizinischer Praxisassistent',
	'Medizinisches Labor',
	'Mentaltrainer',
	'Mesotherapeut',
	'Mikrokinesitherapeut',
	'Mund-, Kiefer- und Gesichtschirurg',
	'Musiktherapeut',
	'Neonatologe',
	'Nephrologe (Nierenspezialist)',
	'Neuraltherapeut',
	'Neurochirurg',
	'Neurofeedback Therapeut',
	'Neurologe',
	'Neuropathologe',
	'Neuroradiologe',
	'Notarzt',
	'Nuklearmediziner',
	'Onkologe',
	'Optometrist',
	'Ortho-Bionomy Therapeut',
	'Orthoptist',
	'Orthopäde',
	'Orthopädietechniker',
	'Orthopädischer Chirurg',
	'Osteopath',
	'Paartherapeut',
	'Phoniatrologe',
	'Physikalischer und Rehabilitationsmediziner',
	'Physiotherapeut',
	'Plastischer & rekonstruktiver Chirurg',
	'Pneumologe (Lungenspezialist)',
	'Podologe',
	'Polarity Therapeut',
	'Postural Integration Therapeut',
	'Posturologe',
	'Proktologe',
	'Prophylaxeassistent',
	'Psychiater',
	'Psychologe',
	'Psychologe / Komplementärpsychotherapeut',
	'Psychosomatischer Mediziner',
	'Psychotherapeut',
	'Psychotherapeutischer Berater',
	'Psychotherapeutischer Coach',
	'Pädiatrischer Infektiologe',
	'Pädiatrischer Physiotherapeut',
	'Pädiatrischer Rheumatologe',
	'Radio-Onkologie-Spezialist',
	'Radiologe',
	'Rebalancing Therapeut',
	'Rebirth Therapeut',
	'Reflexologietherapeut',
	'Reiki Therapeut',
	'Relaxation Therapeut',
	'Reproduktionsmediziner (IVF)',
	'Rheumatologe',
	'Rhythmologe',
	'Rolfing/Strukturelle Integration Therapeut',
	'Schmerzspezialist',
	'Schmerztherapeut',
	'Senologe',
	'Sexologe',
	'Sexualtherapeut',
	'Shiatsu Therapeut',
	'Sophrologe',
	'Spezialist für Beckenbodenrehabilitation',
	'Spezialist für Haartransplantation',
	'Spezialist für Lasermedizin',
	'Spezialist für Perinatalmedizin',
	'Spezialist für Perinatalmedizin',
	'Spezialist für Pränataler Medizin',
	'Spezialist für Pädiatrische Radiologie (Kinderradiologie)',
	'Spezialist für Traditionelle Chinesische Medizin (TCM)',
	'Spezialist für Tropen- und Reisemedizin',
	'Spezialist für maternale Medizin',
	'Spezialist für ästhetische Beratung',
	'Spezialist für ästhetische Medizin',
	'Spezialist für Mikronährstoffmedizin',
	'Spezialist in Kolorektale und Robotik Chirurgie',
	'Sportmediziner',
	'Sportphysiotherapeut',
	'Staatlich anerkannter Homöopath',
	'Staatlich anerkannter Kunsttherapeut',
	'Sumathu Therapeut',
	'TCM Ernährungstherapeut',
	'TCM Naturheilpraktiker',
	'TCM Naturheilpraktiker mit eidg. Dipl.',
	'TCM Pflanzentherapeut',
	'Tanztherapeut',
	'Tauch- und Hyperbarmediziner',
	'Thalassotherapeut',
	'Therapeut der Traditionellen Tibetischen Medizin (TTM)',
	'Therapeut für Alexander Technik',
	'Therapeut für Anwendungen mit Blutegeln',
	'Therapeut für Metamorphische Massage',
	'Therapeut für Thai-Massage',
	'Touch For Health Therapeut',
	'Toxikologe',
	'Trager Therapeut',
	'Traumatologe',
	'Unicist Homöopath',
	'Urogynäkolog',
	'Urologe',
	'Venenarzt',
	'Virtuelle Notfallberatung für Erwachsene',
	'Virtuelle Notfallberatung für Kinder',
	'Viszeralchirurg',
	'WAM Ernährungstherapeut',
	'WAM Pflanzenheilkundler',
	'WAM/TEN Naturheilpraktiker',
	'WAM/TEN Naturheilpraktiker mit staatlicher Anerkennung',
	'Wirbelsäulenchirurg',
	'Yoga-Therapeut',
	'Zahnarzt',
	'Zahntechniker',
	'Zerviko-fazial Chirurg',
	'Zootherapeut',
	'Zytopathologe',
	'Ästhetischer Spezialist'
]

export const Encyclopedia = () => {
	const isMobile = useIsMobile()
	const { t } = useTranslation()

	const tKey = getTKey('encyclopedia')

	const auth = useAppSelector(state => state.auth)

	const numColumns = 4

	const linksPerColumn = Math.ceil(encyclopediaLinks.length / numColumns)

	const columns = Array.from({ length: numColumns }, (_, columnIndex) =>
		encyclopediaLinks.slice(columnIndex * linksPerColumn, (columnIndex + 1) * linksPerColumn)
	)

	return (
		<AppLayout renderHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
			<div className="flex flex-col gap-y-6 px-5 lg:px-[30px] pt-6 pb-14 lg:pb-[100px]">
				<h1 className="lg:text-4xl text-2xl font-bold font-domine text-primary text-center">
					{t(tKey('titles.specialitiesList'))}
				</h1>
				<div className="flex flex-col gap-y-3">
					<Breadcrumb
						theme="dark"
						options={[
							{ name: t(tKey('titles.specialitiesList')), href: '/encyclopedia', current: true }
						]}
					/>
					<div className="border-b border-[#D3E3F1] w-full h-px" />
					<div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:grid-cols-4 gap-x-10 text-sm text-secondary">
						{columns.map((column, columnIndex) => (
							<div key={columnIndex}>
								{column.map((link, linkIndex) => (
									<p key={linkIndex} className="cursor-pointer break-all hover:underline">
										{link}
									</p>
								))}
							</div>
						))}
					</div>
				</div>
			</div>
			<Footer />
		</AppLayout>
	)
}
