import { useTranslation } from 'react-i18next'

import { DeleteIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'

export const AppointmentError = () => {
	const { t } = useTranslation()
	return (
		<AppLayout renderDashboardHeader>
			<div className="flex flex-col items-center justify-center mt-48">
				<img src={DeleteIcon} alt="Checkmark" className=" mx-auto h-14 w-14 mb-10 object-contain" />

				<h1 className="text-primary font-domine text-2xl font-bold leading-8 capitalize text-center mb-3.5">
					{t('appointment.bookingFailed')}
				</h1>
				<p className="text-secondary leading-6 text-base font-nunito text-center font-normal">
					{t('appointment.failPayment')}
				</p>
			</div>
		</AppLayout>
	)
}
