import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { SendButtonIcon, TransparentHeartIcon } from 'assets/icons'
import FileUpload from 'components/app/file-upload'
import { useTranslation } from 'react-i18next'
import ticketService from 'services/ticket-service'
import { getTKey } from 'utils/language'

interface SupportProps {
	onClose: () => void
}

export const Support = ({ onClose }: SupportProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('support')

	const [ticketData, setTicketData] = useState({
		subject: '',
		text: '',
		files: [] as File[],
		email: ''
	})

	const createTicket = (data: FormData) => {
		ticketService
			.createTicket(data)
			.then(() => {
				toast.success(t(tKey('toast.requestSent')))
				onClose()
			})
			.catch(() => toast.error(t(tKey('toast.requestError'))))
	}

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		if (ticketData.files.length > 5) return
		const formData = new FormData()
		formData.append('subject', ticketData.subject)
		formData.append('text', ticketData.text)
		formData.append('email', ticketData.email)
		if (ticketData.files.length > 0) {
			ticketData.files.map(file => formData.append('files', file))
		}
		createTicket(formData)
	}
	return (
		<div className="lg:w-[387px] lg:h-[494px] z-50 rounded-xl fixed bottom-10 right-10 bg-white pb-4">
			<div className="bg-wave bg-cover bg-no-repeat bg-bottom p-5 flex flex-col gap-y-8">
				<div className="flex justify-between">
					<div className="flex flex-col gap-x-1.5">
						<h2 className="text-2xl text-[#FFF]">{t(tKey('welcome'))}</h2>
						<p className="text-sm text-[#FFF]">{t(tKey('message'))}</p>
					</div>
					<div className="flex gap-x-8">
						<img src={TransparentHeartIcon} />
						<ChevronDownIcon className="h-6 w-6 stroke-white cursor-pointer" onClick={onClose} />
					</div>
				</div>
				<p className="text-sm text-[#FFF]">{t(tKey('support'))}</p>
			</div>
			<form onSubmit={handleSubmit} className="flex flex-col gap-y-[18px] p-5 pb-0">
				<div className="relative z-0">
					<input
						type="email"
						onChange={e => setTicketData(prev => ({ ...prev, email: e.target.value }))}
						required
						id="floating_standard"
						className="block py-2.5 px-0 w-full bg-transparent border-0 border-b border-[#D3E3F1] appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
						placeholder=" "
					/>
					<label
						htmlFor="floating_standard"
						className="absolute text-secondary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
						{t(tKey('email'))}
					</label>
				</div>

				<div className="relative z-0">
					<input
						type="text"
						onChange={e => setTicketData(prev => ({ ...prev, subject: e.target.value }))}
						required
						id="floating_standard"
						className="block py-2.5 px-0 w-full bg-transparent border-0 border-b border-[#D3E3F1] appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
						placeholder=" "
					/>
					<label
						htmlFor="floating_standard"
						className="absolute text-secondary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
						{t(tKey('subject'))}
					</label>
				</div>
				<textarea
					required
					onChange={e => setTicketData(prev => ({ ...prev, text: e.target.value }))}
					className="border-none px-0 py-2 placeholder:text-secondary focus:ring-0 focus:outline-none"
					placeholder={t(tKey('content'))}
				/>
				<button className="z-50" type="submit">
					<img src={SendButtonIcon} className="fixed right-10 bottom-0 w-[100px] h-[100px]" />
				</button>
			</form>
			<FileUpload
				files={ticketData.files}
				allowMultiple={true}
				maximumAllowedFiles={5}
				onDismiss={() => {
					setTicketData(prev => ({ ...prev, files: prev.files.slice(0, 5) }))
				}}
				onDelete={(files?: File[]) => {
					setTicketData(prev => ({ ...prev, files: files as File[] }))
				}}
				onUpload={(files: File[]) =>
					setTicketData(prev => ({
						...prev,
						files: [...prev?.files, ...files]
					}))
				}
				className="overflow-y-auto h-36"
				name="email-attachment"
			/>
		</div>
	)
}
