import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

type Option = { name: string; href: string; current: boolean }

interface BreadcrumbProps {
	options: Option[]
	showHome?: boolean
	theme?: 'light' | 'dark'
}

export const Breadcrumb = ({ options, showHome = true, theme = 'light' }: BreadcrumbProps) => {
	return (
		<nav className="flex" aria-label="Breadcrumb">
			<ol role="list" className="flex items-center space-x-2 lg:space-x-4">
				{showHome && (
					<li>
						<div>
							<Link
								to="/"
								className={clsx(
									'max-lg:text-xs opacity-60 hover:opacity-100',
									theme === 'light' ? 'text-white' : 'text-primary'
								)}>
								<span>Home</span>
							</Link>
						</div>
					</li>
				)}
				{options.map((option, index) => (
					<li key={option.name}>
						<div className="flex items-center">
							{(showHome || index !== 0) && (
								<ChevronRightIcon
									className="h-5 w-5 flex-shrink-0 text-gray-400"
									aria-hidden="true"
								/>
							)}
							<Link
								to={option.href}
								className={clsx(
									'lg:ml-4 ml-2 text-xs lg:text-sm font-medium',
									theme === 'light' ? 'text-white' : 'text-primary',
									option.current ? 'opacity-100' : 'opacity-60'
								)}>
								{option.name}
							</Link>
						</div>
					</li>
				))}
			</ol>
		</nav>
	)
}
