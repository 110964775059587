import { CameraIcon, ProfilePhotoIcon } from 'assets/icons'
import React, { InputHTMLAttributes, useState } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	image?: string
	onUpload?: (file: File) => void
}

export const ImageUpload = ({ name, image, onUpload }: InputProps) => {
	const [file, setFile] = useState<File | null>(null)

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (!files || files.length === 0) {
			setFile(null)
		} else {
			setFile(files[0])
			onUpload?.(files[0])
		}
	}

	return (
		<div>
			<div className="relative">
				<input
					type="file"
					className="hidden w-full appearance-none"
					accept=".jpg,.jpeg,.png"
					name={name}
					onChange={handleInputChange}
				/>
				<div
					className="relative flex flex-col cursor-pointer mx-auto justify-center items-center text-white rounded-full w-30 h-30"
					onClick={() => {
						const fileInput = document.querySelector(`[name=${name}]`) as HTMLInputElement
						fileInput.click()
					}}>
					{file ? (
						<img
							src={URL.createObjectURL(file)}
							alt="profile-image"
							className="object-cover rounded-full w-24 h-24"
						/>
					) : image ? (
						<img src={image ?? ProfilePhotoIcon} className="rounded-full w-24 h-24 object-cover" />
					) : (
						<img src={ProfilePhotoIcon} className="rounded-full w-24 h-24" />
					)}
					<img src={CameraIcon} className="absolute bottom-0 translate-x-10 h-[38px] w-[38px]" />
				</div>
			</div>
		</div>
	)
}
