import { useTranslation } from 'react-i18next'
import { getTKey } from 'utils/language'
import { calculateServicePrice } from 'utils/price'

interface ServiceCardProps {
	id: string
	name: string
	price: string
	hasInsurance: boolean
	onSelect: (id: string) => void
}

export const ServiceCard = ({ id, name, price, hasInsurance, onSelect }: ServiceCardProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('companyDetails')
	return (
		<div
			style={{ boxShadow: '0px 4px 24px 0px #00000014' }}
			className="rounded-xl flex-1 min-w-max border border-[#CFD8DC] pt-3 flex flex-col justify-between min-h-[114px]">
			<div className="flex flex-col gap-y-2.5 px-6">
				<div className="flex flex-col gap-y-1">
					<h3 className="font-domine font-bold text-primary">{name}</h3>
					<p className="text-xs md:text-sm text-[#757575]">
						{calculateServicePrice(price, hasInsurance)}
					</p>
				</div>
			</div>
			<div
				onClick={() => onSelect(id)}
				className="bg-primary text-white whitespace-nowrap cursor-pointer font-domine font-bold text-sm px-3 py-2 flex justify-center gap-x-2.5 rounded-b-lg">
				<p>{t(tKey('book'))}</p>
			</div>
		</div>
	)
}
