import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DateTime } from 'luxon'

import { DangerIcon, ProfilePhotoIcon } from 'assets/icons'
import { EllipsisMenu } from 'components/app/menu'
import { Modal } from 'components/app/modal'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import topicService from 'services/topic-service'
import { getDuration } from 'utils/date'
import { getTKey } from 'utils/language'

interface TopicsProps {
	id: string
	articleId: string
	title: string
	author: User
	content: string
	replies: number
	insertedAt: string
	onUpdate: () => void
}

export const Topics = ({
	id,
	articleId,
	title,
	author,
	content,
	insertedAt,
	replies,
	onUpdate
}: TopicsProps) => {
	const isMobile = useIsMobile()
	const { t } = useTranslation()
	const tKey = getTKey('articles.topics')
	const navigate = useNavigate()

	const user = useAppSelector(state => state.user)
	const showEllipsis = user._id === author?._id

	const [showModal, setShowModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [topicData, setTopicData] = useState({
		title,
		content
	})

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		topicService.updateTopic(id, topicData).then(() => {
			setShowModal(false)
			toast.success(t(tKey('toast.updateSuccess')))
			onUpdate()
		})
	}

	const handleDelete = () => {
		topicService.deleteTopic(id).then(() => {
			setShowDeleteModal(false)
			toast.success(t(tKey('toast.deleteSuccess')))
			onUpdate()
		})
	}

	return (
		<div className="flex gap-x-4">
			{showDeleteModal && (
				<Modal width={isMobile ? 'w-[344px]' : 'w-[512px]'} showCrossIcon={false}>
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col max-lg:items-center lg:flex-row gap-x-4">
							<img src={DangerIcon} className="shrink-0 w-[40px] h-[40px]" />
							<div className="flex max-lg:items-center flex-col gap-y-2">
								<h2 className="font-domine text-lg text-primary">{t(tKey('deleteTopic'))}</h2>
								<p className="text-[#7F9AB2] text-sm">{t(tKey('deleteTopicConfirmation'))}</p>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row lg:justify-end lg:items-center max-lg:gap-y-5 gap-x-3">
							<button
								onClick={() => setShowDeleteModal(false)}
								className="button-shadow border border-[#D1D5DB] rounded-md px-[17px] py-[9px] text-sm text-[#7F9AB2] hover:text-black hover:border-black">
								{t('relatedPersons.modal.buttons.cancel')}
							</button>
							<button
								onClick={handleDelete}
								className="button-shadow rounded-md px-[17px] py-[9px] bg-[#DC2626] hover:bg-red-700 text-white">
								{t('relatedPersons.modal.buttons.delete')}
							</button>
						</div>
					</div>
				</Modal>
			)}
			{showModal && (
				<Modal
					crossIconPosition="top-[22px] right-4"
					isFullHeight
					width="lg:w-[690px] w-full"
					onClose={() => setShowModal(false)}>
					<h1 className="text-primary text-2xl font-domine font-bold mb-4">
						{t(tKey('updateTopic'))}
					</h1>
					<form
						onSubmit={handleSubmit}
						style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
						className="relative flex flex-col justify-end w-full">
						<input
							placeholder={t(tKey('placeholder.title'))}
							name="title"
							autoFocus={true}
							value={topicData.title}
							onChange={e => setTopicData(prev => ({ ...prev, title: e.target.value }))}
							required
							className="text-[20px] px-6 pt-3.5 focus:border-[#D3E3F1] border-b-0 border-t focus:outline-none focus:ring-0 border-[#D3E3F1] rounded-t-xl placeholder:text-[#4C6780B2]"
						/>
						<textarea
							placeholder={t(tKey('placeholder.description'))}
							name="content"
							value={topicData.content}
							onChange={e => setTopicData(prev => ({ ...prev, content: e.target.value }))}
							rows={3}
							required
							className="border-b border-t-0 px-6 pb-3.5 pt-2.5 focus:border-[#D3E3F1] focus:outline-none focus:ring-0 border-[#D3E3F1] rounded-b-xl placeholder:text-[#4C6780B2]"></textarea>
						<button
							type="submit"
							className="absolute cursor-pointer right-3 mb-3.5 rounded-full bg-[#7F9AB22E] p-1.5">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="25"
								height="24"
								viewBox="0 0 25 24"
								fill="none">
								<path
									d="M4.7998 18.05V5.95001L19.1498 12L4.7998 18.05ZM5.4998 17L17.3498 12L5.4998 7.00001V11L9.9998 12L5.4998 13V17Z"
									fill="#123258"
									fillOpacity="0.3"
								/>
							</svg>
						</button>
					</form>
				</Modal>
			)}
			<img
				src={
					!author || author?.profile_url === 'health_corner' || author?.profile_url === 'crm'
						? ProfilePhotoIcon
						: author?.profile_url
				}
				alt="user"
				className="lg:w-14 lg:h-14 h-[30px] w-[30px] shrink-0 object-cover rounded-full"
			/>
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
				className="px-6 pt-4 pb-3 border border-[#D3E3F1] rounded-xl w-full flex flex-col gap-y-3.5">
				<div className="flex flex-col gap-y-[3px]">
					<div className="flex justify-between items-center">
						<div className="flex items-center gap-x-4">
							<h5 className="text-primary font-bold">{author?.fname + ' ' + author?.lname}</h5>
							<p className="text-[#12325880] text-xs">
								{getDuration(DateTime.fromISO(insertedAt))}
							</p>
						</div>
						{showEllipsis && (
							<EllipsisMenu
								options={[
									{
										name: t(tKey('update')),
										action: () => {
											setShowModal(true)
										}
									},
									{
										name: t(tKey('delete')),
										action: () => {
											setShowDeleteModal(true)
										}
									}
								]}
							/>
						)}
					</div>
					<h3 className="text-primary text-xl ">{title}</h3>
					<p className="text-primary">{content}</p>
				</div>
				<div className="flex gap-x-6">
					<div className="flex gap-x-1">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="19"
							height="19"
							viewBox="0 0 19 19"
							fill="none">
							<path
								d="M14.7501 13.775V11.75C14.7501 11 14.4813 10.3563 13.9438 9.8188C13.4063 9.2813 12.7626 9.01255 12.0126 9.01255H4.7376L7.9251 12.2L7.5501 12.575L3.7251 8.75005L7.5501 4.92505L7.9251 5.30005L4.7376 8.48755H12.0126C12.9126 8.48755 13.6813 8.8063 14.3188 9.4438C14.9563 10.0813 15.2751 10.85 15.2751 11.75V13.775H14.7501Z"
								fill="#26B6A6"
							/>
						</svg>
						<p
							onClick={() => navigate(`/community_lexikon/${articleId}/topic/${id}`)}
							className="text-[#26B6A6] text-sm cursor-pointer">
							{t(tKey('reply'))}
						</p>
					</div>
					<p
						className="text-[#26B6A6] text-sm cursor-pointer"
						onClick={() => navigate(`/community_lexikon/${articleId}/topic/${id}`)}>
						{replies
							? replies <= 1
								? t(tKey('viewReply')) + ' ' + replies
								: t(tKey('viewReplies')) + ' ' + replies
							: t(tKey('viewReply')) + ' ' + 0}
					</p>
				</div>
			</div>
		</div>
	)
}
