import axios from 'services/axios'

const getCompanyServices = (id: string): Promise<Service[]> => {
	return axios.get(`/company/service/${id}`).then(response => response.data)
}
const getServiceSchedule = (id: string): Promise<Schedule[]> => {
	return axios.get(`/service/scedule/${id}`).then(response => response.data)
}
const service = {
	getCompanyServices,
	getServiceSchedule
}

export default service
