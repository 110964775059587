import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

interface TabProps {
	setTab: (tab: string) => void
	selectedTab: string
	tabs: string[]
	variant?: 'default' | 'pill'
}

export const Tabs = ({ tabs, selectedTab, setTab, variant = 'default' }: TabProps) => {
	const { t } = useTranslation()
	return (
		<div>
			<nav
				className={clsx(
					'-mb-px',
					variant === 'pill'
						? 'grid auto-cols-auto grid-flow-col border border-[#CFD8DC80] bg-white rounded-full'
						: 'flex gap-x-5'
				)}
				aria-label="Tabs">
				{tabs.map(tab => (
					<div
						key={tab}
						onClick={() => setTab(tab)}
						className={clsx(
							`group py-1.5  font-bold flex flex-col gap-y-2 items-center cursor-pointer justify-center lg:text-base text-sm order-2`,
							tab === selectedTab
								? variant === 'pill'
									? 'py-3 border-0 bg-[#F5F7FA] text-primary text-lg rounded-full'
									: 'border-[#123258] text-primary border-b'
								: variant === 'default'
								? 'text-[#7F9AB2]'
								: 'text-primary text-lg py-3'
						)}>
						<p>{variant === 'pill' ? t(`tabs.labels.${tab}`) : tab}</p>
					</div>
				))}
			</nav>
		</div>
	)
}
