import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DateTime } from 'luxon'

import {
	EllipseBottomIcon,
	EllipseRightIcon,
	ProfilePhotoIcon,
	SocialFacebookIcon,
	SocialInstagramIcon,
	SocialLinkedinIcon,
	SocialTwitterIcon
} from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Breadcrumb } from 'components/app/breadcrumb'
import { Button } from 'components/app/button'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { Topics } from 'components/articles/topics'
import { AuthOptions } from 'constants/constants'
import { useAppSelector } from 'hooks'
import articleService from 'services/articles-service'
import commentService from 'services/comment-service'
import topicService from 'services/topic-service'
import { getAppLang, getTKey } from 'utils/language'

export const ArticleDetail = () => {
	const params = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { id: articleId } = params

	const auth = useAppSelector(state => state.auth)
	const user = useAppSelector(state => state.user)

	const appLanguage = getAppLang()
	const tKey = getTKey('articles')

	const [articles, setArticles] = useState<Article[]>()
	const [selectedArticle, setSelectedArticle] = useState<Article>()
	const [headings, setHeadings] = useState<string[]>()
	const [topics, setTopics] = useState<Topic[]>([])
	const [topicData, setTopicData] = useState({
		title: '',
		content: ''
	})
	const [comments, setComments] = useState<{ [id: string]: ArticleComment[] }>()
	const [showDetailedInput, setShowDetailedInput] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [forceRefresh, setForceRefresh] = useState(false)

	useEffect(() => {
		if (articleId) {
			topicService.getTopicsByArticle(articleId).then(res => setTopics(res))
			articleService
				.getArticleById(articleId)
				.then(res => {
					setSelectedArticle(res)
					const regex = /<h1><strong>(.*?)<\/strong><\/h1>/g
					const matches = []
					let match
					if (res.content) {
						while ((match = regex.exec(res.content)) !== null) {
							const content = match[1]
							matches.push(content)
						}
						setHeadings(matches)
					}
				})
				.finally(() => setIsLoading(false))
		}
		articleService.getAllArticles().then(res => setArticles(res))
	}, [articleId, forceRefresh])

	useEffect(() => {
		const fetchComments = async () => {
			if (topics.length > 0) {
				const commentsPromises = topics.map(topic =>
					commentService.getCommentsByTopicId(topic._id).then(res => ({ [topic._id]: res }))
				)
				const commentsArray = await Promise.all(commentsPromises)
				const mergedComments = Object.assign({}, ...commentsArray)
				setComments(mergedComments)
			}
		}
		fetchComments()
	}, [topics])

	const socialIcons = [
		{
			icon: SocialFacebookIcon,
			href: 'https://www.instagram.com/healthcorner_schweiz/'
		},
		{
			icon: SocialInstagramIcon,
			href: 'https://www.instagram.com/healthcorner_schweiz/'
		},
		{
			icon: SocialTwitterIcon,
			href: 'https://www.instagram.com/healthcorner_schweiz/'
		},
		{
			icon: SocialLinkedinIcon,
			href: 'https://www.youtube.com/@HealthCorner-schweiz'
		}
	]

	const scrollToElement = (id: string) => {
		const element = document.getElementById(id)
		if (element) {
			const offset = 20
			const bodyRect = document.body.getBoundingClientRect().top
			const elementRect = element.getBoundingClientRect().top
			const elementPosition = elementRect - bodyRect
			const offsetPosition = elementPosition - offset

			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			})
		}
	}

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		topicService.createTopic({ ...topicData, articleId: articleId as string }).then(() => {
			setTopicData({ title: '', content: '' })
			setShowDetailedInput(false)
			toast.success(t(tKey('topics.toast.createSuccess')))
			setForceRefresh(prev => !prev)
		})
	}

	if (isLoading) {
		return (
			<AppLayout renderDashboardHeader={!!auth.accessToken} renderHeader={!auth.accessToken}>
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t('userDashboard.labels.pleaseWait')}
						</span>
					</div>
				</div>
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader={!!auth.accessToken} renderHeader={!auth.accessToken}>
			<div className="py-[100px] max-lg:px-6 flex flex-col gap-y-8 lg:gap-y-3.5 items-center bg-[#26B6A833]">
				<div className="flex gap-x-2 text-sm justify-center">
					<button className="rounded-[100px] px-3 py-0.5 bg-[#26B6A629]">Symptome</button>
					<button className="rounded-[100px] px-3 py-0.5 bg-[#FFBAC466]">Behandlungen</button>
				</div>
				<h1 className="text-primary lg:leading-[60px] font-domine lg:w-3/4 text-2xl lg:text-[40px] font-bold text-center">
					{selectedArticle?.title}
				</h1>
				<div className="flex items-center gap-x-2.5">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none">
						<path
							d="M14.8846 18.1876C14.3 18.1876 13.8013 17.9812 13.3885 17.5683C12.9756 17.1555 12.7692 16.6568 12.7692 16.0722C12.7692 15.4876 12.9756 14.9888 13.3885 14.576C13.8013 14.1632 14.3 13.9568 14.8846 13.9568C15.4692 13.9568 15.9679 14.1632 16.3808 14.576C16.7936 14.9888 17 15.4876 17 16.0722C17 16.6568 16.7936 17.1555 16.3808 17.5683C15.9679 17.9812 15.4692 18.1876 14.8846 18.1876ZM5.61538 21.1876C5.15513 21.1876 4.77083 21.0334 4.4625 20.7251C4.15417 20.4167 4 20.0324 4 19.5722V6.80294C4 6.34269 4.15417 5.9584 4.4625 5.65006C4.77083 5.34173 5.15513 5.18756 5.61538 5.18756H7.3846V2.95679H8.46152V5.18756H15.6154V2.95679H16.6154V5.18756H18.3846C18.8449 5.18756 19.2292 5.34173 19.5375 5.65006C19.8458 5.9584 20 6.34269 20 6.80294V19.5722C20 20.0324 19.8458 20.4167 19.5375 20.7251C19.2292 21.0334 18.8449 21.1876 18.3846 21.1876H5.61538ZM5.61538 20.1876H18.3846C18.5385 20.1876 18.6795 20.1235 18.8077 19.9953C18.9359 19.867 19 19.726 19 19.5722V10.8029H5V19.5722C5 19.726 5.0641 19.867 5.1923 19.9953C5.32052 20.1235 5.46154 20.1876 5.61538 20.1876ZM5 9.80294H19V6.80294C19 6.6491 18.9359 6.50808 18.8077 6.37986C18.6795 6.25166 18.5385 6.18756 18.3846 6.18756H5.61538C5.46154 6.18756 5.32052 6.25166 5.1923 6.37986C5.0641 6.50808 5 6.6491 5 6.80294V9.80294Z"
							fill="#123258"
						/>
					</svg>
					<p className="text-secondary">
						{DateTime.fromISO(selectedArticle?.dateCreated as string)
							.setLocale(appLanguage)
							.toFormat('dd. LLLL yyyy')}
					</p>
				</div>
				<div className="px-[30px] lg:hidden w-full py-3 rounded-full border border-[#34B6FF] flex justify-around">
					{socialIcons.map(socialIcon => (
						<Link to={socialIcon.href as string} key={socialIcon.icon}>
							<img className="cursor-pointer" src={socialIcon.icon} />
						</Link>
					))}
				</div>
			</div>
			<div className="lg:px-14 px-6 pt-8">
				<Breadcrumb
					options={[
						{ name: t(tKey('articles')), href: '/community_lexikon', current: false },
						{
							name: selectedArticle?.title as string,
							href: `/community_lexikon/${selectedArticle?._id}`,
							current: true
						}
					]}
					theme="dark"
				/>
			</div>
			<div className="py-10 px-5 flex justify-center items-center">
				<img
					src={selectedArticle?.image}
					className="w-[750px] h-[286px] object-cover rounded-xl"
					alt="article-image"
				/>
			</div>

			<div className="lg:px-14 px-6 flex gap-x-14">
				<div className="h-fit max-lg:hidden basis-[25%] py-6 px-8 bg-[#D3E3F14D] rounded-[22px] flex flex-col gap-y-[13px]">
					<h5 className="text-primary font-bold text-xl">{t(tKey('onThisPage'))}</h5>
					{headings?.map((heading, index) => (
						<p
							onClick={() => scrollToElement(heading as string)}
							key={index}
							className="text-primary cursor-pointer hover:text-[#13BAB4]">
							{heading}
						</p>
					))}
				</div>
				<div className="lg:basis-[50%]">
					<div
						className="articleContent"
						dangerouslySetInnerHTML={{
							__html: auth.accessToken
								? (selectedArticle?.content as string)
								: (selectedArticle?.content.substring(
										0,
										selectedArticle?.content.indexOf(
											'<h1><strong>',
											selectedArticle?.content.indexOf(
												'</strong></h1>',
												selectedArticle?.content.indexOf('</strong></h1>') + '</strong></h1>'.length
											) + '</strong></h1>'.length
										)
								  ) as string)
						}}
					/>
					{!auth.accessToken && (
						<div className="flex flex-col gap-y-4">
							<div className="p-4 text-primary font-bold bg-[#D3E3F180] rounded-[20px]">
								<h3 className="text-xl text-justify text-primary font-normal mb-1">
									Interessanter Beitrag? Möchten Sie diesen Beitrag komplett lesen, kommentieren und
									sehen wie sich andere User dazu geäussert haben? Dann melden Sie sich jetzt an
									oder registrieren sich kostenlos.
								</h3>
							</div>
							<div className="relative grow overflow-hidden grid auto-cols-auto lg:grid-flow-col rounded-xl lg:py-8 lg:px-6 items-center place-items-center bg-[#D3E3F1]">
								<div className="flex items-center flex-col py-3 lg:px-3.5 pr-3.5 gap-y-10">
									<div className="flex flex-col items-center gap-y-3 text-center">
										<h3 className="capitalize text-primary text-lg lg:text-[20px] font-bold font-domine">
											{t('appointment.create.headings.signIn')}
										</h3>
										<p className="text-[#7F9AB2] max-lg:text-sm">
											{t('appointment.create.headings.welcomeBack')}
										</p>
									</div>
									<Button
										onClick={() => navigate(`/auth?tab=${AuthOptions.LOGIN}`)}
										className="rounded-full z-50">
										{t('appointment.create.headings.login')}
									</Button>
								</div>
								<div className="max-lg:border-b lg:border-r max-lg:w-11/12 max-lg:h-2 border-dashed border-primary h-full w-2" />
								<div className="flex flex-col items-center gap-y-10 py-3 lg:pl-3.5 lg:px-3.5">
									<div className="flex flex-col items-center gap-y-3 text-center">
										<h3 className="capitalize text-primary text-lg lg:text-[20px] font-bold font-domine">
											{t('appointment.create.headings.newToHealthCorner')}
										</h3>
										<p className="text-[#7F9AB2] max-lg:text-sm">
											{t('appointment.create.headings.getStarted')}
										</p>
									</div>
									<Button
										onClick={() => navigate(`/auth?tab=${AuthOptions.REGISTER}`)}
										className="rounded-full z-50">
										{t('appointment.create.headings.signUp')}
									</Button>
								</div>
								<img
									src={EllipseRightIcon}
									className="absolute -left-20 lg:-left-10 max-lg:h-[300px]"
								/>
								<img src={EllipseBottomIcon} className="absolute -right-16 -top-20 max-lg:hidden" />
								<img
									src={EllipseRightIcon}
									className="-scale-x-100 absolute -right-20 lg:hidden h-[300px]"
								/>
							</div>
						</div>
					)}
					{auth.accessToken && (
						<p className="p-6 mt-8 text-primary font-bold bg-[#D3E3F180] rounded-[20px]">
							Hast du oder jemand in deinem Umfeld bereits Erfahrungen zu diesem Thema gemacht?
							Möchtest du deine Erfahrungen in der Community Mitteilen und auch die Meinung von
							anderen Mitgliedern oder Experten hören?
						</p>
					)}
					{auth.accessToken && (
						<div className="w-full h-1 border-b border-dashed border-[#7F9AB2] my-8" />
					)}
					{auth.accessToken && (
						<div className="flex flex-col gap-y-[22px]">
							<div className="flex items-center gap-x-2.5">
								<h3 className="text-primary text-[28px] font-bold">{t(tKey('topics.title'))}</h3>
								<p className="py-2.5 px-5 rounded-full bg-[#D3E3F14D] text-primary text-[18px] font-bold">
									{topics.length}
								</p>
							</div>
							<div>
								<div className="flex gap-x-4 items-center">
									<img
										src={
											!user.profile_url ||
											user.profile_url === 'health_corner' ||
											user.profile_url === 'crm'
												? ProfilePhotoIcon
												: user.profile_url
										}
										alt="user"
										className="lg:h-14 lg:w-14 shrink-0 h-[30px] w-[30px] object-cover rounded-full"
									/>
									<div className="grow overflow-clip">
										{showDetailedInput ? (
											<form
												onSubmit={handleSubmit}
												style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
												className="relative flex flex-col justify-end">
												<input
													placeholder={t(tKey('topics.placeholder.title'))}
													name="title"
													autoFocus={true}
													value={topicData.title}
													onChange={e => setTopicData(prev => ({ ...prev, title: e.target.value }))}
													required
													className="text-[20px] px-6 pt-3.5 focus:border-[#D3E3F1] border-b-0 border-t focus:outline-none focus:ring-0 border-[#D3E3F1] rounded-t-xl placeholder:text-[#4C6780B2]"
												/>
												<textarea
													placeholder={t(tKey('topics.placeholder.description'))}
													name="content"
													value={topicData.content}
													onChange={e =>
														setTopicData(prev => ({ ...prev, content: e.target.value }))
													}
													rows={3}
													required
													className="border-b border-t-0 px-6 pb-3.5 pt-2.5 focus:border-[#D3E3F1] focus:outline-none focus:ring-0 border-[#D3E3F1] rounded-b-xl placeholder:text-[#4C6780B2]"></textarea>
												<button
													type="submit"
													className="absolute cursor-pointer right-3 mb-3.5 rounded-full bg-[#7F9AB22E] p-1.5">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="25"
														height="24"
														viewBox="0 0 25 24"
														fill="none">
														<path
															d="M4.7998 18.05V5.95001L19.1498 12L4.7998 18.05ZM5.4998 17L17.3498 12L5.4998 7.00001V11L9.9998 12L5.4998 13V17Z"
															fill="#123258"
															fillOpacity="0.3"
														/>
													</svg>
												</button>
											</form>
										) : (
											<div className="relative grow flex items-center">
												<input
													style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
													placeholder={t(tKey('topics.placeholder.addTopic'))}
													onClick={() => setShowDetailedInput(true)}
													className="px-6 py-3.5 w-full placeholder:text-[#4C6780B2] focus:border-[#D3E3F1] border border-[#D3E3F1] rounded-xl"
												/>
												<div className="absolute right-3 rounded-full bg-[#7F9AB22E] p-1.5">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="25"
														height="24"
														viewBox="0 0 25 24"
														fill="none">
														<path
															d="M4.7998 18.05V5.95001L19.1498 12L4.7998 18.05ZM5.4998 17L17.3498 12L5.4998 7.00001V11L9.9998 12L5.4998 13V17Z"
															fill="#123258"
															fillOpacity="0.3"
														/>
													</svg>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="w-full h-1 border-b border-[#EEE] my-6" />
								{topics
									.sort(
										(a, b) =>
											DateTime.fromISO(b.dateUpdated).toMillis() -
											DateTime.fromISO(a.dateUpdated).toMillis()
									)
									.map((topic, index) => (
										<>
											{index > 0 && <div className="w-full h-1 border-b border-[#EEE] my-6" />}
											<Topics
												key={topic._id}
												id={topic._id}
												articleId={articleId as string}
												insertedAt={topic.dateUpdated}
												title={topic.title}
												author={topic.creatorId}
												content={topic.content}
												replies={comments?.[topic._id]?.length as number}
												onUpdate={() => setForceRefresh(prev => !prev)}
											/>
										</>
									))}
							</div>
						</div>
					)}
				</div>
				<div className="h-fit max-lg:hidden basis-[25%] flex flex-col gap-y-6">
					<div className="px-[30px] py-3 rounded-full border border-[#34B6FF] flex justify-around">
						{socialIcons.map(socialIcon => (
							<Link to={socialIcon.href as string} key={socialIcon.icon}>
								<img className="cursor-pointer" src={socialIcon.icon} />
							</Link>
						))}
					</div>
					<div className="flex flex-col card-shadow rounded-xl">
						<h4 className="text-primary bg-[#D3E3F14D] rounded-t-xl px-4 py-3 text-lg font-bold">
							{t(tKey('latestArticle'))}
						</h4>
						<div className="flex flex-col gap-y-3 p-4 text-primary">
							{articles
								?.sort(
									(a, b) =>
										DateTime.fromISO(b.dateUpdated).toMillis() -
										DateTime.fromISO(a.dateUpdated).toMillis()
								)
								.slice(0, 3)
								?.map(article => (
									<Link
										to={`/community_lexikon/${article._id}`}
										key={article._id}
										className="hover:underline hover:text-[#13BAB4]">
										{article.title}
									</Link>
								))}
						</div>
					</div>
				</div>
			</div>
			<Footer className="mt-14" />
		</AppLayout>
	)
}
