import { MobileStethoscopeIcon, StethoscopeIcon } from 'assets/icons'
import clsx from 'clsx'
import useIsMobile from 'hooks/useIsMobile'
import { useState } from 'react'

interface ImageSliderProps {
	images: { name: string; url: string }[]
	page?: string
}

export const ImageSlider = ({ images, page }: ImageSliderProps) => {
	const [currentIndex, setCurrentIndex] = useState(0)

	const handleSlider = (index: number) => {
		setCurrentIndex(index)
	}

	const isMobile = useIsMobile()

	return (
		<div className="relative flex items-center justify-center">
			<img
				src={images?.[0]?.url ? images[0].url : isMobile ? MobileStethoscopeIcon : StethoscopeIcon}
				alt="slider"
				className="w-full h-[203px] object-cover object-center"
			/>

			{images.length > 1 && (
				<div className="bg-[#00000033] absolute rounded-full flex items-center space-x-[5px] p-1 mx-auto bottom-2">
					{images?.map((_image, index) => (
						<div
							key={index}
							className={clsx(
								'rounded-full cursor-pointer',
								index === currentIndex ? 'h-4 w-4 bg-white' : 'h-2 w-2 bg-[#D3E3F1]'
							)}
							onClick={() => handleSlider(index)}></div>
					))}
				</div>
			)}
		</div>
	)
}
