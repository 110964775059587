import axios from 'services/axios'

const getReviewsByCompany = (companyId: string): Promise<Review[]> => {
	return axios.get(`/reviews/${companyId}`).then(response => response.data)
}

const createReview = (data: ReviewForm) => {
	return axios.post('/review', data).then(response => response.data)
}

const reviewService = {
	getReviewsByCompany,
	createReview
}

export default reviewService
