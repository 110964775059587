import axios from 'services/axios'

const getCommentsByTopicId = (id: string): Promise<ArticleComment[]> => {
	return axios.get(`/commentsByTopic/${id}`).then(response => response.data)
}

const getCommentsByParent = (id: string): Promise<ChildComment> => {
	return axios.get(`/commentsByParent/${id}`).then(response => response.data)
}

const createComment = (data: CommentForm) => {
	return axios.post('/comment', data).then(response => response.data)
}

const deleteComment = (id: string) => {
	return axios.delete(`/comment/${id}`).then(response => response.data)
}

const commentService = {
	getCommentsByTopicId,
	getCommentsByParent,
	createComment,
	deleteComment
}

export default commentService
