import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

import clsx from 'clsx'

interface SelectProps
	extends React.DetailedHTMLProps<
		React.SelectHTMLAttributes<HTMLSelectElement>,
		HTMLSelectElement
	> {
	children: React.ReactNode
	labelText?: string
	index?: string
	errors?: FieldErrors<FieldValues>
	name: string
	register?: UseFormRegister<any>
}

export const Select: React.FC<SelectProps> = ({
	labelText,
	errors,
	index,
	onChange,
	children,
	register,
	name,
	className,
	...restProp
}) => {
	const errorText = errors?.[name]?.message as string
	return (
		<div className="relative max-lg:w-full">
			{labelText && (
				<label htmlFor={name} className="block text-sm font-medium leading-6 text-primary">
					{labelText}
				</label>
			)}
			<select
				{...restProp}
				{...(register?.(name) ?? {})}
				className={clsx(
					'font-semibold focus:ring-0 border border-[#D3E3F1] focus:border-primary rounded-md py-3 text-sm outline-none',
					className?.includes('text') ? className : 'text-primary',
					className
				)}>
				{children}
			</select>
			{errorText && <p className="text-xs mt-1 text-red-600">{errorText}</p>}
		</div>
	)
}

export const SelectLabel: React.FC<SelectProps> = ({
	labelText,
	errors,
	index,
	onChange,
	children,
	register,
	name,
	className,
	...restProp
}) => {
	const errorText = errors?.[name]?.message as string
	return (
		<div className="relative max-lg:w-full">
			<div className={clsx('relative overflow-visible rounded-md', className)}>
				<label
					htmlFor="name"
					className={clsx('absolute text-sm bg-white px-0.5 text-primary left-3 -top-2.5')}>
					{labelText}
				</label>
				<select
					{...restProp}
					{...(register?.(name) ?? {})}
					className={clsx(
						'w-full focus:ring-0 border border-[#D3E3F1] focus:border-primary rounded-md py-3 outline-none',
						className?.includes('text') ? className : 'text-primary',
						className
					)}>
					{children}
				</select>
			</div>
			{errorText && <p className="text-xs mt-1 text-red-600">{errorText}</p>}
		</div>
	)
}
