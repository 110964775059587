import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {} as User

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		saveUser(state, action: PayloadAction<User>) {
			return action.payload
		}
	}
})

export const { saveUser } = userSlice.actions
export default userSlice.reducer
