import { ChevronLeftIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { DocumentPreviewIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { MenuDropdown } from 'components/app/dropdown'
import { AppLayout } from 'components/app/layout'
import { MobileNavigation } from 'components/app/mobile-navigation'
import { Table } from 'components/app/table'
import { Tabs } from 'components/app/tabs'
import { DocumentCard } from 'components/cards/document-card'
import { UploadModal } from 'components/document/upload-modal'
import { InputDate } from 'components/inputs/date'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import notesService from 'services/notes-service'
import userService from 'services/user-service'
import { getAppLang, getTKey } from 'utils/language'

enum DocumentTabs {
	DOCUMENT = 'documents',
	NOTE = 'notes'
}

enum DocumentType {
	ALL = 'all',
	GENERAL = 'general',
	APPOINTMENT = 'appointment',
	PERSONAL = 'personal'
}

enum NoteType {
	ALL = 'all',
	GENERAL = 'general',
	APPOINTMENT = 'appointment'
}

export const UserDocuments = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('documents')
	const appLanguage = getAppLang()

	const user = useAppSelector(state => state.user)

	const [documents, setDocuments] = useState<UserDocument>()
	const [notes, setNotes] = useState<UserNote[]>()
	const [selectedDate, setSelectedDate] = useState<string>()
	const [documentType, setDocumentType] = useState(DocumentType.ALL)
	const [noteType, setNoteType] = useState(NoteType.ALL)
	const [selectedNote, setSelectedNote] = useState<UserNote>()
	const [searchText, setSearchText] = useState<string>()
	const [showModal, setShowModal] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)
	const [forceRefresh, setForceRefresh] = useState(false)

	useEffect(() => {
		setSearchParams({ tab: DocumentTabs.DOCUMENT }, { replace: true })
	}, [])

	useEffect(() => {
		if (user) {
			notesService.getUserNotes(user._id).then(response => setNotes(response))
		}
		userService
			.getUserDocuments()
			.then(response => setDocuments(response))
			.finally(() => setIsLoading(false))
	}, [forceRefresh])

	const { filteredDocuments } = useMemo(() => {
		if (isLoading) return { filteredDocuments: [] }
		const { filteredDocuments } = (documents as UserDocument)?.files
			.filter(document => {
				if (documentType === DocumentType.APPOINTMENT) return document.appointmentId !== null
				if (documentType === DocumentType.GENERAL) return document.appointmentId === null
				if (documentType === DocumentType.PERSONAL) return document.uploadSource === 'Platform'
				return true
			})
			.reduce(
				(prev, curr) => {
					if (searchText) {
						const addedBy = curr.createdBy
							? curr.createdBy?.fname + ' ' + curr.createdBy?.lname
							: user?.fname + ' ' + user?.lname
						if (
							(curr.fileName.toLowerCase().includes(searchText.toLocaleLowerCase()) ||
								curr.companyId?.company_name?.toLowerCase().includes(searchText.toLowerCase()) ||
								addedBy?.toLowerCase().includes(searchText.toLowerCase())) &&
							(!selectedDate ||
								(selectedDate &&
									DateTime.fromISO(curr.uploadedAt).hasSame(
										DateTime.fromFormat(selectedDate, 'dd LLL yyyy'),
										'day'
									)))
						) {
							return { filteredDocuments: [...prev.filteredDocuments, curr] }
						}
					} else if (selectedDate) {
						if (
							DateTime.fromISO(curr.uploadedAt).hasSame(
								DateTime.fromFormat(selectedDate, 'dd LLL yyyy'),
								'day'
							)
						) {
							return { filteredDocuments: [...prev.filteredDocuments, curr] }
						}
					} else {
						return { filteredDocuments: [...prev.filteredDocuments, curr] }
					}

					return prev
				},
				{
					filteredDocuments: [] as UserDocument['files']
				}
			)
		return { filteredDocuments }
	}, [documents, searchText, selectedDate, documentType])

	const getSelectedType = (): string => {
		if (searchParams.get('tab') === DocumentTabs.DOCUMENT) {
			if (documentType === DocumentType.GENERAL) return t(tKey('generalDocuments'))
			if (documentType === DocumentType.APPOINTMENT) return t(tKey('appointmentDocuments'))
			if (documentType === DocumentType.PERSONAL) return t(tKey('myDocuments'))
			return t(tKey('allDocuments'))
		} else {
			if (noteType === NoteType.GENERAL) return t(tKey('generalNotes'))
			if (noteType === NoteType.APPOINTMENT) return t(tKey('appointmentNotes'))
			return t(tKey('allNotes'))
		}
	}

	const renderDocumentsTBody = (documentsData: UserDocument['files']) => {
		return (
			<tbody>
				{documentsData.map(file => (
					<tr className="card-shadow rounded-lg" key={file._id}>
						<td className="tw-table-td rounded-l-lg border-l border-[#26B6A64D]">
							<div className="flex gap-x-5 items-center">
								<div className="px-1.5 py-[3px] rounded bg-[#F6F8FB] border border-[#EEE]">
									<img src={DocumentPreviewIcon} />
								</div>
								{file.fileName}
							</div>
						</td>
						<td className="tw-table-td">
							{file.createdBy
								? file.createdBy.fname + ' ' + file.createdBy.lname
								: user.fname + ' ' + user.lname}
						</td>
						{documentType !== DocumentType.GENERAL && documentType !== DocumentType.PERSONAL && (
							<td className="tw-table-td">{file.companyId?.company_name}</td>
						)}
						{documentType !== DocumentType.GENERAL && documentType !== DocumentType.PERSONAL && (
							<td className="tw-table-td">
								<Link to={`/user/appointments/details/${file.appointmentId?._id}`}>
									{file.appointmentId?.appointmentNumber.toString().padStart(7, '0')}
								</Link>
							</td>
						)}
						<td className="tw-table-td">
							<div className="flex flex-col">
								<span className="text-base text-ellipsis line-clamp-1">
									{DateTime.fromISO(file.uploadedAt).setLocale(appLanguage).toFormat('dd LLL yyyy')}
								</span>
								<span className="text-secondary font-normal text-center">
									{DateTime.fromISO(file.uploadedAt).toFormat('HH:mm')}
								</span>
							</div>
						</td>
						<td className="tw-table-td border-r border-[#26B6A64D] rounded-r-lg">
							<Link download={file.fileName} to={file.fileURL} target="__blank">
								<div className="hover:bg-[#12325814] py-3 px-2.5 w-fit">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="18"
										height="18"
										viewBox="0 0 18 18"
										fill="none">
										<path
											d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
											stroke="#123258"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5.25 7.5L9 11.25L12.75 7.5"
											stroke="#123258"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9 11.25V2.25"
											stroke="#123258"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
							</Link>
						</td>
					</tr>
				))}
			</tbody>
		)
	}

	const menuOptions =
		searchParams.get('tab') === DocumentTabs.DOCUMENT
			? [
					{ label: t(tKey('allDocuments')), value: DocumentType.ALL },
					{ label: t(tKey('generalDocuments')), value: DocumentType.GENERAL },
					{ label: t(tKey('appointmentDocuments')), value: DocumentType.APPOINTMENT },
					{ label: t(tKey('myDocuments')), value: DocumentType.PERSONAL }
			  ]
			: [
					{ label: t(tKey('allNotes')), value: NoteType.ALL },
					{ label: t(tKey('generalNotes')), value: NoteType.GENERAL },
					{ label: t(tKey('appointmentNotes')), value: NoteType.APPOINTMENT }
			  ]

	if (isMobileFilterOpen) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="py-4 px-5 grid grid-cols-3">
					<ChevronLeftIcon
						className="w-4 h-4 cursor-pointer"
						onClick={() => setIsMobileFilterOpen(false)}
					/>
					<h1 className="text-center font-domine font-bold text-sm text-primary">
						{t(tKey('filters'))}
					</h1>
				</div>
				<div className="p-5 flex flex-col gap-y-5">
					<InputDate
						placeholder={t(tKey('date'))}
						value={selectedDate}
						onDateChange={(value: string) => setSelectedDate(value)}
						className="w-full p-4 rounded"
					/>
					<select
						name="type"
						onChange={e =>
							searchParams.get('tab') === DocumentTabs.DOCUMENT
								? setDocumentType(e.target.value as DocumentType)
								: setNoteType(e.target.value as NoteType)
						}
						value={searchParams.get('tab') === DocumentTabs.DOCUMENT ? documentType : noteType}
						className="text-primary text-sm font-semibold rounded p-4 border border-[#D3E3F1] focus:border-[#06AB91] focus:ring-0">
						{menuOptions.map(option => (
							<option value={option.value} key={option.label}>
								{option.label}
							</option>
						))}
					</select>
					<div className="self-end flex gap-x-6">
						<button
							onClick={() => {
								setSelectedDate('')
								searchParams.get('tab') === DocumentTabs.DOCUMENT
									? setDocumentType(DocumentType.ALL)
									: setNoteType(NoteType.ALL)
							}}
							className="text-primary font-bold text-sm">
							{t(tKey('reset'))}
						</button>
						<Button onClick={() => setIsMobileFilterOpen(false)} className="font-bold text-sm">
							{t(tKey('apply'))}
						</Button>
					</div>
				</div>
			</AppLayout>
		)
	}

	if (selectedNote) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="py-4 px-5 grid grid-cols-3">
					<ChevronLeftIcon className="w-4 h-4" onClick={() => setSelectedNote(undefined)} />
					<h1 className="text-center font-domine font-bold text-sm text-primary">
						{t(tKey('notes'))}
					</h1>
				</div>
				<div className="p-5 flex flex-col gap-y-4 w-full">
					<div className="flex justify-between">
						<p className="text-sm whitespace-nowrap text-primary items-center flex gap-x-1.5">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								viewBox="0 0 14 14"
								className="lg:hidden"
								fill="none">
								<path
									d="M7.00033 6.64064C6.51571 6.64064 6.10289 6.47013 5.76187 6.1291C5.42084 5.78807 5.25033 5.37525 5.25033 4.89064C5.25033 4.40602 5.42084 3.99319 5.76187 3.65216C6.10289 3.31114 6.51571 3.14062 7.00033 3.14062C7.48494 3.14062 7.89776 3.31114 8.23879 3.65216C8.57981 3.99319 8.75033 4.40602 8.75033 4.89064C8.75033 5.37525 8.57981 5.78807 8.23879 6.1291C7.89776 6.47013 7.48494 6.64064 7.00033 6.64064ZM2.91699 10.2528V9.89832C2.91699 9.65751 2.9871 9.43222 3.12733 9.22244C3.26755 9.01267 3.4562 8.84982 3.69328 8.7339C4.2437 8.46991 4.7945 8.27191 5.34567 8.13991C5.89685 8.00791 6.44841 7.94191 7.00033 7.94191C7.55225 7.94191 8.1038 8.00791 8.65498 8.13991C9.20615 8.27191 9.75695 8.46991 10.3074 8.7339C10.5445 8.84982 10.7331 9.01267 10.8733 9.22244C11.0135 9.43222 11.0837 9.65751 11.0837 9.89832V10.2528C11.0837 10.4241 11.0255 10.5679 10.9092 10.6841C10.7929 10.8004 10.6492 10.8586 10.4779 10.8586H3.52275C3.3515 10.8586 3.20772 10.8004 3.09142 10.6841C2.97514 10.5679 2.91699 10.4241 2.91699 10.2528Z"
									fill="#4C6780"
								/>
							</svg>
							<span className="text-secondary text-xs">{t(tKey('by'))}</span>
							{selectedNote.createdBy?.fname + ' ' + selectedNote.createdBy?.lname}
						</p>
						<div className="flex items-center gap-x-2 text-xs text-secondary">
							<p>
								{DateTime.fromMillis(selectedNote.created)
									.setLocale(appLanguage)
									.toFormat('LLL dd, yyyy')}
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="3"
								height="4"
								viewBox="0 0 3 4"
								fill="none">
								<circle cx="1.5" cy="2" r="1.5" fill="#D9D9D9" />
							</svg>
							<p>{DateTime.fromMillis(selectedNote.created).toFormat('HH:mm')}</p>
						</div>
					</div>
					<p
						dangerouslySetInnerHTML={{ __html: selectedNote.note }}
						className="text-sm text-primary"
					/>
				</div>
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader>
			{showModal && (
				<UploadModal
					onRefresh={() => setForceRefresh(prev => !prev)}
					onClose={() => setShowModal(false)}
				/>
			)}
			{isLoading && (
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black font-bold -ml-9 text-xl animate-pulse">
							Please Wait...
						</span>
					</div>
				</div>
			)}
			<div className="lg:px-[60px] px-5 pt-10 max-lg:pb-[90px] lg:min-h-screen">
				<div className="flex flex-col gap-y-5 lg:gap-y-8">
					<div className="flex justify-between items-center">
						<Tabs
							tabs={[t(tKey('tabs.documents')), t(tKey('tabs.notes'))]}
							setTab={(value: string) =>
								setSearchParams({
									tab:
										value === t(tKey('tabs.documents')) ? DocumentTabs.DOCUMENT : DocumentTabs.NOTE
								})
							}
							selectedTab={
								searchParams.get('tab') === DocumentTabs.NOTE
									? t(tKey('tabs.notes'))
									: t(tKey('tabs.documents'))
							}
						/>
						<div className="flex gap-x-4 items-center">
							{searchParams.get('tab') === DocumentTabs.DOCUMENT && (
								<div className="relative max-lg:hidden flex items-center">
									<input
										name="document"
										placeholder={t(tKey('document'))}
										className="rounded-full placeholder:text-secondary text-primary focus:outline-none border-[#D3E3F1] focus:ring-0 focus:border-[#06AB91] search-shadow px-4 py-[5px] w-[322px]"
										onChange={event => setSearchText(event.target.value)}
									/>
									<MagnifyingGlassIcon className="absolute h-6 w-6 right-3 stroke-primary stroke-2" />
								</div>
							)}
							{isMobile && searchParams.get('tab') === DocumentTabs.DOCUMENT && (
								<div
									style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
									onClick={() => {
										if (isSearchVisible) {
											setSearchText('')
											return setIsSearchVisible(false)
										}
										setIsSearchVisible(true)
									}}
									className={clsx('rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer', {
										'bg-[#1232584D]': isSearchVisible
									})}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none">
										<g clipPath="url(#clip0_2734_2383)">
											<path
												d="M12.9162 11.6672H12.2579L12.0245 11.4422C13.0245 10.2755 13.5412 8.68386 13.2579 6.99219C12.8662 4.67552 10.9329 2.82552 8.59954 2.54219C5.07454 2.10885 2.10788 5.07552 2.54121 8.60052C2.82454 10.9339 4.67455 12.8672 6.99121 13.2589C8.68288 13.5422 10.2745 13.0255 11.4412 12.0255L11.6662 12.2589V12.9172L15.2079 16.4589C15.5495 16.8005 16.1079 16.8005 16.4495 16.4589C16.7912 16.1172 16.7912 15.5589 16.4495 15.2172L12.9162 11.6672ZM7.91621 11.6672C5.84121 11.6672 4.16621 9.99219 4.16621 7.91719C4.16621 5.84219 5.84121 4.16719 7.91621 4.16719C9.99121 4.16719 11.6662 5.84219 11.6662 7.91719C11.6662 9.99219 9.99121 11.6672 7.91621 11.6672Z"
												fill="#123258"
											/>
										</g>
										<defs>
											<clipPath id="clip0_2734_2383">
												<rect width="20" height="20" fill="white" />
											</clipPath>
										</defs>
									</svg>
								</div>
							)}
							{isMobile && (
								<div
									style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
									onClick={() => setIsMobileFilterOpen(true)}
									className={clsx('rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer', {
										'bg-[#1232584D]':
											selectedDate || searchParams.get('tab') === DocumentTabs.DOCUMENT
												? documentType !== DocumentType.ALL
												: noteType !== NoteType.ALL
									})}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none">
										<path
											d="M4.16699 17.5V7.5M15.8337 2.5V5.83333V2.5ZM15.8337 17.5V9.16667V17.5ZM10.0003 2.5V12.5V2.5ZM10.0003 17.5V15.8333V17.5ZM4.16699 2.5V4.16667V2.5Z"
											stroke="#123258"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M14.1667 7.49968C14.1667 8.42015 14.9129 9.16634 15.8333 9.16634C16.7538 9.16634 17.5 8.42015 17.5 7.49968C17.5 6.5792 16.7538 5.83301 15.8333 5.83301C14.9129 5.83301 14.1667 6.5792 14.1667 7.49968Z"
											stroke="#123258"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M8.33366 14.1667C8.33366 15.0871 9.07985 15.8333 10.0003 15.8333C10.9208 15.8333 11.667 15.0871 11.667 14.1667C11.667 13.2462 10.9208 12.5 10.0003 12.5C9.07985 12.5 8.33366 13.2462 8.33366 14.1667Z"
											stroke="#123258"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
										<path
											d="M2.49967 5.83366C2.49967 6.75413 3.24587 7.50033 4.16634 7.50033C5.08682 7.50033 5.83301 6.75413 5.83301 5.83366C5.83301 4.91318 5.08682 4.16699 4.16634 4.16699C3.24587 4.16699 2.49967 4.91318 2.49967 5.83366Z"
											stroke="#123258"
											strokeWidth="1.2"
											strokeLinecap="round"
										/>
									</svg>
								</div>
							)}
							{isMobile && searchParams.get('tab') === DocumentTabs.DOCUMENT && (
								<div
									style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
									onClick={() => setShowModal(true)}
									className="rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none">
										<path
											d="M11.5 11.5808L9.73463 13.3404C9.64424 13.434 9.53126 13.4808 9.39568 13.4808C9.26009 13.4808 9.14358 13.4321 9.04615 13.3346C8.93973 13.241 8.88973 13.1231 8.89615 12.9808C8.90257 12.8385 8.95257 12.7205 9.04615 12.6269L11.4346 10.2385C11.5962 10.0769 11.7846 9.99615 12 9.99615C12.2154 9.99615 12.4038 10.0769 12.5654 10.2385L14.9538 12.6269C15.0474 12.7205 15.0984 12.8368 15.1067 12.9758C15.1151 13.1148 15.0641 13.2349 14.9538 13.336C14.8603 13.4287 14.7433 13.4728 14.6029 13.4683C14.4625 13.4638 14.3436 13.4148 14.2462 13.3212L12.5 11.5808V17.3846C12.5 17.5385 12.5641 17.6795 12.6923 17.8077C12.8205 17.9359 12.9615 18 13.1154 18H18.5C19.2 18 19.7917 17.7583 20.275 17.275C20.7583 16.7917 21 16.2 21 15.5C21 14.8 20.7583 14.2083 20.275 13.725C19.7917 13.2417 19.2 13 18.5 13H17V11C17 9.61667 16.5125 8.4375 15.5375 7.4625C14.5625 6.4875 13.3833 6 12 6C10.6167 6 9.4375 6.4875 8.4625 7.4625C7.4875 8.4375 7 9.61667 7 11H6.46155C5.53333 11 4.72436 11.3417 4.03462 12.025C3.34487 12.7083 3 13.5333 3 14.5C3 15.4667 3.34167 16.2917 4.025 16.975C4.70833 17.6583 5.53333 18 6.5 18H8.5C8.64167 18 8.76042 18.048 8.85625 18.144C8.95208 18.2399 9 18.3588 9 18.5007C9 18.6425 8.95208 18.7612 8.85625 18.8567C8.76042 18.9522 8.64167 19 8.5 19H6.5C5.25257 19 4.19071 18.5662 3.31442 17.6986C2.43814 16.831 2 15.7705 2 14.5173C2 13.3327 2.40769 12.3058 3.22308 11.4365C4.03846 10.5673 4.99615 10.1 6.09615 10.0346C6.32052 8.5782 6.99039 7.375 8.10577 6.425C9.22116 5.475 10.5192 5 12 5C13.6714 5 15.0893 5.58214 16.2536 6.74642C17.4179 7.91071 18 9.32857 18 11V12H18.6154C19.5731 12.0308 20.3766 12.3824 21.026 13.0548C21.6753 13.7272 22 14.5423 22 15.5C22 16.4808 21.6683 17.3093 21.0048 17.9856C20.3414 18.6619 19.5192 19 18.5385 19H13.1154C12.6551 19 12.2708 18.8458 11.9625 18.5375C11.6542 18.2292 11.5 17.8449 11.5 17.3846V11.5808Z"
											fill="black"
										/>
									</svg>
								</div>
							)}
							{!isMobile && (
								<InputDate
									placeholder={t(tKey('date'))}
									value={selectedDate}
									onDateChange={(value: string) => setSelectedDate(value)}
									className="rounded-full py-1.5 "
								/>
							)}
							{!isMobile && (
								<MenuDropdown
									options={
										searchParams.get('tab') === DocumentTabs.DOCUMENT
											? [
													t(tKey('allDocuments')),
													t(tKey('generalDocuments')),
													t(tKey('appointmentDocuments')),
													t(tKey('myDocuments'))
											  ]
											: [t(tKey('allNotes')), t(tKey('generalNotes')), t(tKey('appointmentNotes'))]
									}
									setActive={(value: string) => {
										if (searchParams.get('tab') === DocumentTabs.DOCUMENT) {
											if (value === t(tKey('generalDocuments')))
												return setDocumentType(DocumentType.GENERAL)
											if (value === t(tKey('appointmentDocuments')))
												return setDocumentType(DocumentType.APPOINTMENT)
											if (value === t(tKey('myDocuments')))
												return setDocumentType(DocumentType.PERSONAL)
											return setDocumentType(DocumentType.ALL)
										} else {
											if (value === t(tKey('generalNotes'))) return setNoteType(NoteType.GENERAL)
											if (value === t(tKey('appointmentNotes')))
												return setNoteType(NoteType.APPOINTMENT)
											return setNoteType(NoteType.ALL)
										}
									}}
									selected={getSelectedType()}
								/>
							)}
							{searchParams.get('tab') === DocumentTabs.DOCUMENT && !isMobile && (
								<button
									onClick={() => setShowModal(true)}
									className="text-primary text-sm font-semibold rounded-full border border-primary focus:border-[#06AB91] py-1.5 px-4">
									{t(tKey('upload'))}
								</button>
							)}
						</div>
					</div>
					{!isMobile && searchParams.get('tab') === DocumentTabs.DOCUMENT && (
						<Table
							headers={
								documentType !== DocumentType.GENERAL && documentType !== DocumentType.PERSONAL
									? [
											t(tKey('documentName')),
											t(tKey('addedBy')),
											t(tKey('companyName')),
											t(tKey('appointmentId')),
											t(tKey('date')),
											''
									  ]
									: [t(tKey('documentName')), t(tKey('addedBy')), t(tKey('date')), '']
							}
							items={filteredDocuments?.sort(
								(a, b) =>
									DateTime.fromISO(b.uploadedAt).toMillis() -
									DateTime.fromISO(a.uploadedAt).toMillis()
							)}
							renderComponent={renderDocumentsTBody}
						/>
					)}
					<div
						className={clsx('flex flex-col gap-y-4', {
							hidden: searchParams.get('tab') === DocumentTabs.NOTE
						})}>
						{isSearchVisible && (
							<div className="relative lg:hidden flex items-center">
								<input
									name="document"
									placeholder={t(tKey('document'))}
									autoFocus
									className="rounded w-full placeholder:text-secondary text-primary focus:outline-none border-[#D3E3F1] focus:ring-0 focus:border-[#06AB91] search-shadow px-4 py-[5px]"
									onChange={event => setSearchText(event.target.value)}
								/>
								<XMarkIcon
									onClick={() => {
										setSearchText('')
										setIsSearchVisible(false)
									}}
									className="absolute h-6 w-6 right-3 stroke-primary stroke-2"
								/>
							</div>
						)}
						{isMobile &&
							searchParams.get('tab') === DocumentTabs.DOCUMENT &&
							filteredDocuments
								?.sort(
									(a, b) =>
										DateTime.fromISO(b.uploadedAt).toMillis() -
										DateTime.fromISO(a.uploadedAt).toMillis()
								)
								.map(documentData => (
									<DocumentCard
										key={documentData._id}
										name={documentData.fileName}
										url={documentData.fileURL}
										addedBy={
											documentData.createdBy
												? documentData.createdBy.fname + ' ' + documentData.createdBy.lname
												: user.fname + ' ' + user.lname
										}
										appointmentId={documentData.appointmentId}
										companyName={documentData.companyId?.company_name}
									/>
								))}
					</div>
					{searchParams.get('tab') === DocumentTabs.NOTE && (
						<div className="flex flex-wrap items-start gap-x-5 gap-y-8">
							{notes
								?.filter(note => {
									if (noteType === NoteType.APPOINTMENT) return note.id_appointment !== undefined
									if (noteType === NoteType.GENERAL) return note.id_appointment === undefined
									return true
								})
								.filter(note => {
									if (selectedDate)
										return DateTime.fromMillis(note.created).hasSame(
											DateTime.fromFormat(selectedDate, 'dd LLL yyyy'),
											'day'
										)
									return true
								})
								.map(note => (
									<div
										key={note._id}
										onClick={() => isMobile && setSelectedNote(note)}
										style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.12)' }}
										className="p-3 max-lg:cursor-pointer lg:p-6 max-lg:w-full rounded-lg flex flex-col gap-y-8 border border-[#26B6A64D]">
										<div
											className={clsx('flex items-center justify-between', {
												'flex-row-reverse': !!note.id_appointment
											})}>
											{note.id_appointment && (
												<Link
													to={`/user/appointments/details/${note.id_appointment._id}`}
													className="self-end text-xs px-3 bg-[#D3E3F14D] rounded-full py-1">
													{note.id_appointment.appointmentNumber.toString().padStart(7, '0')}
												</Link>
											)}
											<p
												dangerouslySetInnerHTML={{ __html: note.note }}
												className="text-primary max-lg:max-h-[42px] max-lg:text-ellipsis max-lg:line-clamp-2"
											/>
										</div>
										<div className="flex justify-between lg:gap-x-14">
											<p className="text-sm whitespace-nowrap italic text-primary items-center flex gap-x-1.5">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="14"
													height="14"
													viewBox="0 0 14 14"
													className="lg:hidden"
													fill="none">
													<path
														d="M7.00033 6.64064C6.51571 6.64064 6.10289 6.47013 5.76187 6.1291C5.42084 5.78807 5.25033 5.37525 5.25033 4.89064C5.25033 4.40602 5.42084 3.99319 5.76187 3.65216C6.10289 3.31114 6.51571 3.14062 7.00033 3.14062C7.48494 3.14062 7.89776 3.31114 8.23879 3.65216C8.57981 3.99319 8.75033 4.40602 8.75033 4.89064C8.75033 5.37525 8.57981 5.78807 8.23879 6.1291C7.89776 6.47013 7.48494 6.64064 7.00033 6.64064ZM2.91699 10.2528V9.89832C2.91699 9.65751 2.9871 9.43222 3.12733 9.22244C3.26755 9.01267 3.4562 8.84982 3.69328 8.7339C4.2437 8.46991 4.7945 8.27191 5.34567 8.13991C5.89685 8.00791 6.44841 7.94191 7.00033 7.94191C7.55225 7.94191 8.1038 8.00791 8.65498 8.13991C9.20615 8.27191 9.75695 8.46991 10.3074 8.7339C10.5445 8.84982 10.7331 9.01267 10.8733 9.22244C11.0135 9.43222 11.0837 9.65751 11.0837 9.89832V10.2528C11.0837 10.4241 11.0255 10.5679 10.9092 10.6841C10.7929 10.8004 10.6492 10.8586 10.4779 10.8586H3.52275C3.3515 10.8586 3.20772 10.8004 3.09142 10.6841C2.97514 10.5679 2.91699 10.4241 2.91699 10.2528Z"
														fill="#4C6780"
													/>
												</svg>
												<span className="text-secondary max-lg:text-xs not-italic">
													{t(tKey('by'))}
												</span>
												{note.createdBy?.fname + ' ' + note.createdBy?.lname}
											</p>
											<div className="flex items-center gap-x-2 text-xs lg:text-sm text-secondary">
												<p>
													{DateTime.fromMillis(note.created)
														.setLocale(appLanguage)
														.toFormat('LLL dd, yyyy')}
												</p>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="3"
													height="4"
													viewBox="0 0 3 4"
													fill="none">
													<circle cx="1.5" cy="2" r="1.5" fill="#D9D9D9" />
												</svg>
												<p>{DateTime.fromMillis(note.created).toFormat('HH:mm')}</p>
											</div>
										</div>
									</div>
								))}
						</div>
					)}
				</div>
			</div>
			<MobileNavigation />
		</AppLayout>
	)
}
