import axios from 'services/axios'

const getMyChats = (userId: string): Promise<Chat[]> => {
	return axios.get(`/chats/${userId}`).then(response => response.data)
}

const createChat = (
	participantOneId: string,
	participantTwoId: string,
	participantOneModel: string,
	participantTwoModel: string
) => {
	return axios
		.post('/chat', { participantOneId, participantTwoId, participantOneModel, participantTwoModel })
		.then(response => response.data)
}

const updateChat = (chatId: string) => {
	return axios.put(`/chat/${chatId}`).then(response => response.data)
}

const getChatMessages = (chatId: string): Promise<ChatMessage[]> => {
	return axios.get(`/chatMessage/${chatId}`).then(response => response.data)
}

const chatService = {
	getMyChats,
	createChat,
	updateChat,
	getChatMessages
}

export default chatService
