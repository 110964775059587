// eslint-disable-next-line
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line
import authService from 'services/auth-service';

export const login = createAsyncThunk(
	'auth/login',
	async (user: { username: string; password: string }, thunkAPI) => {
		try {
			const response = await authService.login(user.username, user.password)
			if (response.status === true) {
				return thunkAPI.fulfillWithValue({ ...response })
			}
			return thunkAPI.rejectWithValue(false)
		} catch (error: any) {
			return thunkAPI.rejectWithValue(false)
		}
	}
)

const initialState = {} as AuthState

const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		logOut(state) {
			return initialState
		}
	},
	extraReducers: builder => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(
			login.fulfilled,
			(
				state: AuthState,
				action: PayloadAction<{
					_id: string
					email: string
					accessToken: string
					fname: string
					lname: string
				}>
			) => {
				const { _id, email, accessToken, fname, lname } = action.payload
				return {
					fname,
					lname,
					email,
					id: _id,
					accessToken
				}
			}
		),
			builder.addCase(login.rejected, (state: AuthState, action: PayloadAction<any>) => {
				return initialState
			})
	}
})

const { reducer } = authSlice

export const { logOut } = authSlice.actions

export default reducer
