import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DateTime } from 'luxon'

import {
	ProfilePhotoIcon,
	SocialFacebookIcon,
	SocialInstagramIcon,
	SocialLinkedinIcon,
	SocialTwitterIcon
} from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { Comment } from 'components/articles/comments'
import { useAppSelector } from 'hooks'
import articleService from 'services/articles-service'
import commentService from 'services/comment-service'
import topicService from 'services/topic-service'
import { getAppLang, getTKey } from 'utils/language'

export const ArticleComments = () => {
	const params = useParams()
	const { articleId, topicId } = params
	const { t } = useTranslation()

	const tKey = getTKey('articles')
	const appLanguage = getAppLang()
	const user = useAppSelector(state => state.user)

	const [selectedTopic, setSelectedTopic] = useState<Topic>()
	const [selectedArticle, setSelectedArticle] = useState<Article>()
	const [comments, setComments] = useState<ArticleComment[]>([])
	const [commentData, setCommentData] = useState({ content: '' })
	const [isLoading, setIsLoading] = useState(true)
	const [forceRefresh, setForceRefresh] = useState(false)
	const [articles, setArticles] = useState<Article[]>([])

	useEffect(() => {
		articleService.getAllArticles().then(res => setArticles(res))
	}, [])

	const socialIcons = [
		{
			icon: SocialFacebookIcon,
			href: 'https://www.instagram.com/healthcorner_schweiz/'
		},
		{
			icon: SocialInstagramIcon,
			href: 'https://www.instagram.com/healthcorner_schweiz/'
		},
		{
			icon: SocialTwitterIcon,
			href: 'https://www.instagram.com/healthcorner_schweiz/'
		},
		{
			icon: SocialLinkedinIcon,
			href: 'https://www.youtube.com/@HealthCorner-schweiz'
		}
	]

	useEffect(() => {
		if (articleId && topicId) {
			topicService.getTopicById(topicId).then(res => setSelectedTopic(res))
			articleService
				.getArticleById(articleId)
				.then(res => setSelectedArticle(res))
				.finally(() => setIsLoading(false))
			commentService.getCommentsByTopicId(topicId).then(res => setComments(res))
		}
	}, [articleId, topicId, forceRefresh])

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		commentService
			.createComment({ content: commentData.content, topicId: topicId as string })
			.then(() => {
				toast.success(t(tKey('comments.toast.createSuccess')))
				setCommentData({ content: '' })
				setForceRefresh(prev => !prev)
			})
	}

	if (isLoading) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t('userDashboard.labels.pleaseWait')}
						</span>
					</div>
				</div>
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader>
			<div className="py-[100px] max-lg:px-6 flex flex-col gap-y-8 lg:gap-y-3.5 items-center bg-[#26B6A833]">
				<h1 className="text-primary lg:leading-[60px] font-domine lg:w-3/4 text-2xl lg:text-[40px] font-bold text-center">
					{selectedArticle?.title}
				</h1>
				<div className="flex items-center gap-x-2.5">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none">
						<path
							d="M14.8846 18.1876C14.3 18.1876 13.8013 17.9812 13.3885 17.5683C12.9756 17.1555 12.7692 16.6568 12.7692 16.0722C12.7692 15.4876 12.9756 14.9888 13.3885 14.576C13.8013 14.1632 14.3 13.9568 14.8846 13.9568C15.4692 13.9568 15.9679 14.1632 16.3808 14.576C16.7936 14.9888 17 15.4876 17 16.0722C17 16.6568 16.7936 17.1555 16.3808 17.5683C15.9679 17.9812 15.4692 18.1876 14.8846 18.1876ZM5.61538 21.1876C5.15513 21.1876 4.77083 21.0334 4.4625 20.7251C4.15417 20.4167 4 20.0324 4 19.5722V6.80294C4 6.34269 4.15417 5.9584 4.4625 5.65006C4.77083 5.34173 5.15513 5.18756 5.61538 5.18756H7.3846V2.95679H8.46152V5.18756H15.6154V2.95679H16.6154V5.18756H18.3846C18.8449 5.18756 19.2292 5.34173 19.5375 5.65006C19.8458 5.9584 20 6.34269 20 6.80294V19.5722C20 20.0324 19.8458 20.4167 19.5375 20.7251C19.2292 21.0334 18.8449 21.1876 18.3846 21.1876H5.61538ZM5.61538 20.1876H18.3846C18.5385 20.1876 18.6795 20.1235 18.8077 19.9953C18.9359 19.867 19 19.726 19 19.5722V10.8029H5V19.5722C5 19.726 5.0641 19.867 5.1923 19.9953C5.32052 20.1235 5.46154 20.1876 5.61538 20.1876ZM5 9.80294H19V6.80294C19 6.6491 18.9359 6.50808 18.8077 6.37986C18.6795 6.25166 18.5385 6.18756 18.3846 6.18756H5.61538C5.46154 6.18756 5.32052 6.25166 5.1923 6.37986C5.0641 6.50808 5 6.6491 5 6.80294V9.80294Z"
							fill="#123258"
						/>
					</svg>
					<p className="text-secondary">
						{DateTime.fromISO(selectedArticle?.dateCreated as string)
							.setLocale(appLanguage)
							.toFormat('LLLL dd, yyyy')}
					</p>
				</div>
				<div className="px-[30px] lg:hidden w-full py-3 rounded-full border border-[#34B6FF] flex justify-around">
					{socialIcons.map(socialIcon => (
						<Link to={socialIcon.href as string} key={socialIcon.icon}>
							<img className="cursor-pointer" src={socialIcon.icon} />
						</Link>
					))}
				</div>
			</div>
			<div className="flex gap-x-14 px-5 lg:px-[60px] mt-10">
				<div className="lg:basis-[75%] flex flex-col gap-y-10 w-full">
					<div className="lg:px-[60px] px-5 flex flex-col gap-y-6">
						<div className="flex flex-col gap-y-3">
							<h1 className="text-primary text-[28px] font-bold">{selectedTopic?.title}</h1>
							<p className="text-primary">{selectedTopic?.content}</p>
						</div>
						<div className="flex gap-x-4">
							<img
								src={
									selectedTopic?.creatorId.profile_url === 'health_corner' ||
									selectedTopic?.creatorId.profile_url === 'crm'
										? ProfilePhotoIcon
										: selectedTopic?.creatorId.profile_url
								}
								className="h-14 w-14 rounded-full object-cover"
							/>
							<div className="flex flex-col gap-y-1">
								<h5 className="text-primary font-bold">
									{selectedTopic?.creatorId.fname + ' ' + selectedTopic?.creatorId.lname}
								</h5>
								<p className="text-xs text-[#12325880]">
									{DateTime.fromISO(selectedTopic?.dateUpdated as string)
										.setLocale(appLanguage)
										.toFormat('dd LLL, yyyy')}
								</p>
							</div>
						</div>
						<div className="w-full h-1 border-b border-[#EEE]" />
					</div>

					<div className="flex flex-col gap-y-[22px]">
						<div className="flex items-center gap-x-2.5">
							<h3 className="text-primary text-[28px] font-bold">{t(tKey('comments.title'))}</h3>
							<p className="py-2.5 px-5 rounded-full bg-[#D3E3F14D] text-primary text-[18px] font-bold">
								{comments.length}
							</p>
						</div>
						<div>
							<div className="flex gap-x-4 items-center">
								<img
									src={
										!user.profile_url ||
										user.profile_url === 'health_corner' ||
										user.profile_url === 'crm'
											? ProfilePhotoIcon
											: user.profile_url
									}
									alt="user"
									className="h-14 w-14 object-cover rounded-full"
								/>
								<div className="grow">
									<form
										onSubmit={handleSubmit}
										style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
										className="relative grow flex items-center w-full">
										<input
											style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
											value={commentData.content}
											onChange={event => setCommentData({ content: event.target.value })}
											placeholder={t(tKey('comments.placeholder.leaveComment'))}
											className="px-6 py-3.5 w-full placeholder:text-[#4C6780B2] focus:border-[#D3E3F1] border border-[#D3E3F1] rounded-xl"
										/>
										<button
											type="submit"
											className="absolute cursor-pointer right-3 rounded-full bg-[#7F9AB22E] p-1.5">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="25"
												height="24"
												viewBox="0 0 25 24"
												fill="none">
												<path
													d="M4.7998 18.05V5.95001L19.1498 12L4.7998 18.05ZM5.4998 17L17.3498 12L5.4998 7.00001V11L9.9998 12L5.4998 13V17Z"
													fill="#123258"
													fillOpacity="0.3"
												/>
											</svg>
										</button>
									</form>
								</div>
							</div>
							<div className="w-full h-1 border-b border-[#EEE] my-6" />
							{comments
								.sort(
									(a, b) =>
										DateTime.fromISO(b.dateUpdated).toMillis() -
										DateTime.fromISO(a.dateUpdated).toMillis()
								)
								.map((comment, index) => (
									<>
										{index > 0 && <div className="w-full h-1 border-b border-[#EEE] my-6" />}
										<Comment
											key={comment._id}
											id={comment._id}
											profile={
												comment.profile_url === 'health_corner' || comment.profile_url === 'crm'
													? ProfilePhotoIcon
													: comment.profile_url
											}
											topicId={topicId as string}
											creatorId={comment.creatorId}
											insertedAt={comment.dateUpdated}
											author={comment.authorName}
											content={comment.content}
											onUpdate={() => setForceRefresh(prev => !prev)}
										/>
									</>
								))}
						</div>
					</div>
				</div>

				<div className="h-fit max-lg:hidden basis-[25%] flex flex-col gap-y-6">
					<div className="px-[30px] py-3 rounded-full border border-[#34B6FF] flex justify-around">
						{socialIcons.map(socialIcon => (
							<Link to={socialIcon.href as string} key={socialIcon.icon}>
								<img className="cursor-pointer" src={socialIcon.icon} />
							</Link>
						))}
					</div>
					<div className="flex flex-col card-shadow rounded-xl">
						<h4 className="text-primary bg-[#D3E3F14D] rounded-t-xl px-4 py-3 text-lg font-bold">
							{t(tKey('latestArticle'))}
						</h4>
						<div className="flex flex-col gap-y-3 p-4 text-primary">
							{articles
								?.sort(
									(a, b) =>
										DateTime.fromISO(b.dateUpdated).toMillis() -
										DateTime.fromISO(a.dateUpdated).toMillis()
								)
								.slice(0, 3)
								?.map(article => (
									<Link
										to={`/community_lexikon/${article._id}`}
										key={article._id}
										className="hover:underline hover:text-[#13BAB4]">
										{article.title}
									</Link>
								))}
						</div>
					</div>
				</div>
			</div>
			<Footer className="mt-20" />
		</AppLayout>
	)
}
