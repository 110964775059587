import { useAppDispatch, useAppSelector } from 'hooks'
import { useEffect } from 'react'
import userService from 'services/user-service'
import { saveUser } from 'slices/user'

export const useLoadResources = () => {
	const dispatch = useAppDispatch()
	const auth = useAppSelector(state => state.auth)

	useEffect(() => {
		const loadResources = () => {
			userService
				.getUserInfo()
				.then(res => dispatch(saveUser(res)))
				.catch(err => console.warn(err))
		}

		if (auth.accessToken) {
			setTimeout(() => {
				loadResources()
			}, 500)
		}
	}, [auth])
}
