import { Language } from 'constants/constants'
import { default as i18n } from 'i18next'

export const getAppLang = () => {
	const lang = (window.localStorage.getItem('i18nLng') || i18n.language) as AppLang
	return lang.includes(Language.DE) ? Language.DE : Language.EN
}

export const getTKey = (namespace: string) => {
	return (key: string): string => `${namespace || ''}.${key}`
}
