import { ReactElement } from 'react'

import clsx from 'clsx'

import { Pagination } from 'components/app/pagination'

type TableParams = {
	headers: string[]
	items: any
	renderComponent: (item: any) => ReactElement<any, any>
}

export const Table = ({ headers, items, renderComponent }: TableParams) => {
	return (
		<table className="min-w-full table-fixed border-separate border-spacing-y-5">
			<thead>
				<tr className="rounded-lg">
					{headers.map((header, index) => (
						<th
							key={header + index}
							scope="col"
							className={clsx(
								`text-sm whitespace-nowrap lg:text-base tw-table-th`,
								index === 0 && 'border-none rounded-l-lg text-left',
								index === headers.length - 1 && 'rounded-r-lg'
							)}>
							{header}
						</th>
					))}
				</tr>
			</thead>
			<Pagination items={items} renderComponent={renderComponent} />
		</table>
	)
}
