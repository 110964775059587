import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import clsx from 'clsx'

interface AccordionProps {
	heading: string
	content: string
}

export const Accordion = ({ heading, content }: AccordionProps) => {
	return (
		<div className="w-full lg:rounded-xl">
			<div className="pt-5 lg:px-5 w-full bg-white lg:shadow-lg">
				<Disclosure>
					{({ open }) => (
						<>
							<Disclosure.Button className="flex border-b border-[#D3E3F1] pb-5 font-bold w-full justify-between text-left text-primary max-lg:text-sm capitalize focus:outline-none">
								<span>{heading}</span>
								<ChevronDownIcon
									className={clsx('h-5 w-5 text-primary', { 'rotate-180 transform': open })}
								/>
							</Disclosure.Button>
							<Disclosure.Panel className={clsx('text-secondary', { 'py-5': open })}>
								{content}
							</Disclosure.Panel>
						</>
					)}
				</Disclosure>
			</div>
		</div>
	)
}
