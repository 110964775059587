import { InputHTMLAttributes } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

import clsx from 'clsx'

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText: string
	name: string
	register?: UseFormRegister<any>
	error?: FieldErrors<FieldValues>
}
export const RadioInput = ({
	value,
	error,
	register,
	checked,
	name,
	labelText,
	onChange,
	...restProps
}: RadioProps) => {
	const { className } = restProps
	const errorText = error?.[name]?.message as string
	return (
		<div className={clsx('flex items-center space-x-2')}>
			<input
				className={clsx(
					className,
					'w-4 h-4 ring-0 checked:ring-0 text-primary border border-[#D3E3F1]'
				)}
				type="radio"
				{...(register?.(name) ?? {})}
				onChange={onChange}
				checked={checked}
				tabIndex={-1}
				value={value}
				{...restProps}
			/>
			<p className="text-[#7F9AB2]">{labelText}</p>
			{errorText && <p className="text-xs text-red-500 mt-1">{errorText}</p>}
		</div>
	)
}
