import axios from 'services/axios'

const getAppointmentNotes = (id: string): Promise<UserNote[]> => {
	return axios.get(`/appointment/notice?id_appointment=${id}`).then(response => response.data)
}

const getUserNotes = (id: string): Promise<UserNote[]> => {
	return axios.get(`/client/notice?id_client=${id}`).then(response => response.data)
}

const notesService = {
	getUserNotes,
	getAppointmentNotes
}

export default notesService
