import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { DateTime } from 'luxon'

import { DangerIcon, ProfilePhotoIcon } from 'assets/icons'
import { EllipsisMenu } from 'components/app/menu'
import { Modal } from 'components/app/modal'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import commentService from 'services/comment-service'
import { getDuration } from 'utils/date'
import { getTKey } from 'utils/language'

interface CommentProps {
	id: string
	topicId: string
	creatorId: string
	author: string
	content: string
	insertedAt: string
	profile: string
	onUpdate: () => void
}

export const Comment = ({
	id,
	topicId,
	creatorId,
	author,
	content,
	insertedAt,
	onUpdate,
	profile
}: CommentProps) => {
	const isMobile = useIsMobile()
	const { t } = useTranslation()
	const tKey = getTKey('articles.comments')

	const user = useAppSelector(state => state.user)
	const showEllipsis = user._id === creatorId

	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showReply, setShowReply] = useState(false)
	const [commentData, setCommentData] = useState({ content: '' })
	const [childComments, setChildComments] = useState<ChildComment>()
	const [forceRefresh, setForceRefresh] = useState(false)
	const [childCommentId, setChildCommentId] = useState<string>()

	useEffect(() => {
		if (id) {
			commentService.getCommentsByParent(id).then(res => setChildComments(res))
		}
	}, [id, forceRefresh])

	useEffect(() => {
		setCommentData({ content: '' })
	}, [showReply])

	const handleDelete = () => {
		commentService.deleteComment(childCommentId ? childCommentId : id).then(() => {
			setShowDeleteModal(false)
			setChildCommentId(undefined)
			toast.success(t(tKey('toast.deleteSuccess')))
			setForceRefresh(prev => !prev)
			onUpdate()
		})
	}

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		commentService
			.createComment({
				content: commentData.content,
				topicId: topicId as string,
				parentCommentId: id
			})
			.then(() => {
				toast.success(t(tKey('toast.createSuccess')))
				setForceRefresh(prev => !prev)
				setShowReply(false)
				setCommentData({ content: '' })
				onUpdate()
			})
	}

	return (
		<div className="flex flex-col gap-y-2.5">
			<div className="flex gap-x-4">
				{showDeleteModal && (
					<Modal width={isMobile ? 'w-[344px]' : 'w-[512px]'} showCrossIcon={false}>
						<div className="flex flex-col gap-y-4">
							<div className="flex flex-col max-lg:items-center lg:flex-row gap-x-4">
								<img src={DangerIcon} className="shrink-0 w-[40px] h-[40px]" />
								<div className="flex max-lg:items-center flex-col gap-y-2">
									<h2 className="font-domine text-lg text-primary">{t(tKey('deleteComment'))}</h2>
									<p className="text-[#7F9AB2] text-sm">{t(tKey('deleteCommentConfirmation'))}</p>
								</div>
							</div>
							<div className="flex flex-col lg:flex-row lg:justify-end lg:items-center max-lg:gap-y-5 gap-x-3">
								<button
									onClick={() => {
										setShowDeleteModal(false)
										setChildCommentId(undefined)
									}}
									className="button-shadow border border-[#D1D5DB] rounded-md px-[17px] py-[9px] text-sm text-[#7F9AB2] hover:text-black hover:border-black">
									{t('relatedPersons.modal.buttons.cancel')}
								</button>
								<button
									onClick={handleDelete}
									className="button-shadow rounded-md px-[17px] py-[9px] bg-[#DC2626] hover:bg-red-700 text-white">
									{t('relatedPersons.modal.buttons.delete')}
								</button>
							</div>
						</div>
					</Modal>
				)}

				<img
					src={profile === 'health_corner' || profile === 'crm' ? ProfilePhotoIcon : profile}
					alt="user"
					className="lg:w-14 lg:h-14 shrink-0 w-[30px] h-[30px] object-cover rounded-full"
				/>

				<div
					style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
					className="px-6 pt-4 pb-3 border border-[#D3E3F1] rounded-xl w-full flex flex-col gap-y-3.5">
					<div className="flex flex-col gap-y-[3px]">
						<div className="flex justify-between items-center">
							<div className="flex items-center gap-x-4">
								<h5 className="text-primary font-bold">{author}</h5>
								<p className="text-[#12325880] text-xs">
									{getDuration(DateTime.fromISO(insertedAt))}
								</p>
							</div>

							{showEllipsis && (
								<EllipsisMenu
									options={[
										{
											name: t(tKey('delete')),
											action: () => {
												setShowDeleteModal(true)
											}
										}
									]}
								/>
							)}
						</div>
						<p className="text-primary">{content}</p>
					</div>
					<div className="flex gap-x-6">
						<div className="flex gap-x-1">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="19"
								height="19"
								viewBox="0 0 19 19"
								fill="none">
								<path
									d="M14.7501 13.775V11.75C14.7501 11 14.4813 10.3563 13.9438 9.8188C13.4063 9.2813 12.7626 9.01255 12.0126 9.01255H4.7376L7.9251 12.2L7.5501 12.575L3.7251 8.75005L7.5501 4.92505L7.9251 5.30005L4.7376 8.48755H12.0126C12.9126 8.48755 13.6813 8.8063 14.3188 9.4438C14.9563 10.0813 15.2751 10.85 15.2751 11.75V13.775H14.7501Z"
									fill="#26B6A6"
								/>
							</svg>
							<p
								onClick={() => setShowReply(prev => !prev)}
								className="text-[#26B6A6] text-sm cursor-pointer">
								{t(tKey('reply'))}
							</p>
						</div>
					</div>
				</div>
			</div>
			{showReply && (
				<div className="grow pl-8 lg:pl-[72px]">
					<form
						onSubmit={handleSubmit}
						style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
						className="relative grow flex items-center w-full">
						<input
							style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
							value={commentData.content}
							onChange={event => setCommentData({ content: event.target.value })}
							placeholder={t(tKey('placeholder.leaveComment'))}
							className="px-6 py-3.5 w-full placeholder:text-[#4C6780B2] focus:border-[#D3E3F1] border border-[#D3E3F1] rounded-xl"
						/>
						<button
							type="submit"
							className="absolute cursor-pointer right-3 rounded-full bg-[#7F9AB22E] p-1.5">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="25"
								height="24"
								viewBox="0 0 25 24"
								fill="none">
								<path
									d="M4.7998 18.05V5.95001L19.1498 12L4.7998 18.05ZM5.4998 17L17.3498 12L5.4998 7.00001V11L9.9998 12L5.4998 13V17Z"
									fill="#123258"
									fillOpacity="0.3"
								/>
							</svg>
						</button>
					</form>
				</div>
			)}

			{childComments &&
				childComments?.childComments
					.sort(
						(a, b) =>
							DateTime.fromISO(b.dateUpdated).toMillis() -
							DateTime.fromISO(a.dateUpdated).toMillis()
					)
					.map(comment => {
						const showChildEllipsis = comment.creatorId === user._id
						return (
							<div key={comment._id} className="pl-8 lg:px-[72px] flex gap-x-4">
								<img
									src={
										comment.profile_url === 'health_corner' || comment.profile_url === 'crm'
											? ProfilePhotoIcon
											: comment.profile_url
									}
									className="w-6 h-6 lg:w-10 lg:h-10 shrink-0 object-cover rounded-full"
								/>
								<div
									style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
									className="px-6 py-3 w-full border border-[#D3E3F1] rounded-xl flex flex-col gap-y-[3px]">
									<div className="flex justify-between items-center">
										<div className="flex items-center gap-x-4">
											<h5 className="text-primary font-bold">{comment.authorName}</h5>
											<p className="text-[#12325880] text-xs">
												{getDuration(DateTime.fromISO(comment.dateUpdated))}
											</p>
										</div>
										{showChildEllipsis && (
											<EllipsisMenu
												options={[
													{
														name: t(tKey('delete')),
														action: () => {
															setShowDeleteModal(true)
															setChildCommentId(comment._id)
														}
													}
												]}
											/>
										)}
									</div>

									<p className="text-primary ">{comment.content}</p>
								</div>
							</div>
						)
					})}
		</div>
	)
}
