import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { BabyIcon, EyeBagIcon, HypoAllergenicIcon, ToothIcon, UltrasoundIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { AppLayout } from 'components/app/layout'
import { MobileNavigation } from 'components/app/mobile-navigation'
import { AppointmentCard } from 'components/cards/appointment-card'
import { CompanyCard } from 'components/cards/company-card'
import appointmentService from 'services/appointment-service'
import companyService from 'services/company-service'
import userService from 'services/user-service'
import { getTKey } from 'utils/language'

export const UserDashboard = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const tKey = getTKey('userDashboard')

	const [user, setUser] = useState<User>()
	const [isLoading, setIsLoading] = useState(true)
	const [appointments, setAppointments] = useState<Appointment[]>([])
	const [companies, setCompanies] = useState<SearchResult[]>([])
	const [companyLogos, setCompanyLogos] = useState<{
		[id: string]: { name: string; url: string }
	}>({})
	const [appointmentStats, setAppointmentStats] = useState<{ title: string; quantity: number }[]>(
		[]
	)

	useEffect(() => {
		userService.getUserInfo().then(res => setUser(res))
		appointmentService.getAppointmentStats().then(res =>
			setAppointmentStats([
				{
					title: t(tKey('appointments.totalAppointments')),
					quantity: res.totalAppointment
				},
				{
					title: t(tKey('appointments.successFulAppointments')),
					quantity: res.schlusskontrolle
				},
				{
					title: t(tKey('appointments.cancelledAppointments')),
					quantity: res.canceled
				}
			])
		)

		companyService.searchCompanies().then(res => setCompanies(res))
	}, [])

	useEffect(() => {
		if (user?._id) {
			appointmentService
				.getAppointmentByUserId()
				.then(response => setAppointments(response))
				.finally(() => setIsLoading(false))
		}
	}, [user?._id])

	useEffect(() => {
		if (appointments) {
			const uniqueCompanyIds = Array.from(
				new Set(appointments.slice(0, 3).map(appointment => appointment.id_company._id))
			)
			const fetchLogos = async (companyId: string) => {
				const response = await companyService.getCompanyLogo(companyId)
				return { [companyId]: response[0] }
			}
			Promise.all(uniqueCompanyIds.map(fetchLogos)).then(response => {
				setCompanyLogos(prevCompanyLogos => ({
					...prevCompanyLogos,
					...response.reduce((acc, curr) => ({ ...acc, ...curr }), {})
				}))
			})
		}

		if (companies) {
			const fetchLogos = async (data: SearchResult) => {
				const response = await companyService.getCompanyLogo(data._id)
				return { [data._id]: response[0] }
			}
			Promise.all(companies.slice(0, 3).map(fetchLogos)).then(response => {
				setCompanyLogos(prevCompanyLogos => ({
					...prevCompanyLogos,
					...response.reduce((acc, curr) => ({ ...acc, ...curr }), {})
				}))
			})
		}
	}, [appointments, companies])

	const categories = [
		{ icon: UltrasoundIcon, heading: t('home.categories.companyTypes.Pharmacy') },
		{ icon: BabyIcon, heading: t('home.categories.companyTypes.Gynecology') },
		{ icon: EyeBagIcon, heading: t('home.categories.companyTypes.Ophthalmologist') },
		{ icon: ToothIcon, heading: t('home.categories.companyTypes.Dentist') },
		{ icon: HypoAllergenicIcon, heading: t('home.categories.companyTypes.Pediatrician') }
	]

	if (isLoading) {
		return (
			<AppLayout renderDashboardHeader>
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t(tKey('labels.pleaseWait'))}
						</span>
					</div>
				</div>
				<MobileNavigation />
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader>
			<div className="lg:px-14 px-[21px] pt-10 lg:pb-10">
				<div className="flex lg:flex-row flex-col max-lg:gap-y-8 lg:gap-x-8">
					<div className="flex flex-col gap-y-8 w-full lg:w-[65%] grow">
						<div className="flex justify-center gap-x-[11px] lg:gap-x-8">
							{appointmentStats.map((stat, index) => (
								<div
									key={stat.title}
									className={clsx(
										'flex flex-col basis-1/3 max-lg:w-[104px] max-lg:h-[90px] max-lg:p-3 lg:px-6 lg:py-5 gap-y-3.5 rounded-[14px]',
										{
											'bg-[#B790D81A]': index === 0,
											'bg-[#F5B7151A]': index === 1,
											'bg-[#0754501A]': index === 2
										}
									)}>
									<h3 className="text-primary max-lg:text-xs font-semibold lg:w-20 2xl:w-full">
										{stat.title}
									</h3>
									<p
										className={clsx('lg:text-[40px] text-xl sm:text-[24px] font-medium', {
											'text-[#B790D8]': index === 0,
											'text-[#F5B715]': index === 1,
											'text-[#075450]': index === 2
										})}>
										{stat.quantity}
									</p>
									<div />
								</div>
							))}
						</div>

						<div className="flex flex-col gap-y-5">
							<h3 className="font-bold font-domine text-primary">
								{t(tKey('labels.recentAppointments'))}
							</h3>
							{appointments
								.slice(0, 3)
								?.map(appointment => (
									<AppointmentCard
										key={appointment._id}
										id={appointment._id}
										payment_mode={appointment.payment_mode}
										date={appointment.from}
										additionalInfo={appointment.additonal_information}
										relatedPerson={appointment.relatedPerson}
										questions={appointment.questions}
										companyImage={companyLogos[appointment.id_company?._id]?.url}
										title={appointment.id_company?.company_name}
										categories={appointment.id_company?.company_type}
										service={appointment.service}
										company={appointment.id_company}
									/>
								))}
						</div>

						<div className="flex flex-col gap-y-5">
							<div className="flex items-center justify-between">
								<h2 className="text-primary text-lg font-bold">{t(tKey('labels.categories'))}</h2>
								<div
									onClick={() => navigate('/search-results')}
									className="flex items-center cursor-pointer gap-x-1.5 lg:hidden">
									<span className="text-sm text-primary font-semibold">
										{t(tKey('labels.viewAll'))}
									</span>
									<ChevronRightIcon className="shrink-0 w-3 h-3" />
								</div>
							</div>

							<div className="flex sm:justify-center gap-x-3.5 overflow-x-auto">
								{categories.map(category => (
									<div
										key={category.heading}
										onClick={() => navigate('/fachrichtungen')}
										className="flex items-center justify-center bg-[#F6F8FB] px-3.5 min-w-[82px] min-h-[82px] lg:min-w-[159px] lg:min-h-[138px] flex-col lg:gap-y-1.5 gap-y-[9px]">
										<img
											className="w-[24px] h-[24px] lg:w-[38px] lg:h-[38px]"
											src={category.icon}
										/>
										<h5 className="text-primary text-[10px] lg:font-semibold lg:text-[17px]">
											{category.heading}
										</h5>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-y-8 w-full lg:max-w-[35%] max-lg:mb-[100px]">
						<div className="flex flex-col gap-y-5">
							<div className="flex justify-between items-center">
								<div className="font-domine text-primary font-bold text-lg">
									{t(tKey('labels.topRatedCompanies'))}
								</div>
								<Link to={'/search-results'} className="text-secondary text-sm underline">
									{t(tKey('labels.viewAll'))}
								</Link>
							</div>
							{companies.slice(0, 3).map((company, index) => (
								<CompanyCard
									key={index}
									id={company._id}
									image={companyLogos[company._id]?.url}
									title={company.company_name}
									address={`${company.address} ${company.address_no}, ${company.zip} ${company.city}`}
									categories={company.company_type}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			<MobileNavigation />
		</AppLayout>
	)
}
