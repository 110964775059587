import { InputHTMLAttributes, useState } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

import clsx from 'clsx'

import { containsOnlyDigits } from 'utils/string'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText?: string
	name: string
	index?: string
	register?: UseFormRegister<any>
	errors?: FieldErrors<FieldValues>
	className?: string
}

export const Input = ({
	labelText,
	index,
	name,
	register,
	errors,
	className,
	...props
}: InputProps) => {
	const errorText = errors?.[name]?.message as string

	return (
		<div className={clsx('grow', { 'gap-y-2': labelText })}>
			{labelText && (
				<label className="whitespace-nowrap" htmlFor={name}>
					{labelText}
				</label>
			)}
			<input
				{...props}
				{...(register?.(name) ?? {})}
				id={index}
				className={clsx(
					'w-full rounded font-normal pl-4 bg-white focus:ring-0 border focus:border-[#D3E3F1] border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm lg:placeholder:text-base focus:outline-none lg:text-md text-sm',
					className?.includes('p') ? className : 'py-3',
					className,
					{
						'pointer-events-none': props.disabled
					}
				)}
			/>
			{errorText && <p className="text-xs text-red-500 mt-1">{errorText}</p>}
			<div />
		</div>
	)
}

export const InputNumber = ({
	labelText,
	name,
	placeholder,
	maxLength,
	errors,
	register,
	value,
	onChange,
	className,
	...props
}: InputProps) => {
	const errorText = errors?.[name]?.message as string

	const [input, setInput] = useState(value?.toString())

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target

		if (!maxLength || value.length <= maxLength) {
			if (containsOnlyDigits(value)) {
				setInput(value)
				onChange?.(event)
			}
			if (value == '') {
				setInput(value)
				onChange?.(event)
			}
		}
	}

	return (
		<div className={clsx('relative grow overflow-visible rounded-md', className)}>
			<label
				htmlFor="name"
				className={clsx(
					'absolute -top-2.5 text-xs px-0.5 text-primary bg-white left-3 transition-all duration-300'
				)}>
				{labelText}
			</label>
			<input
				{...props}
				{...(register?.(name) ?? {})}
				placeholder={placeholder}
				onChange={handleInputChange}
				value={input}
				inputMode="numeric"
				type="text"
				className={clsx(
					'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border focus:border-[#D3E3F1] border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md',
					className
				)}
			/>
			{errorText && <p className="text-xs text-red-500">{errorText}</p>}
		</div>
	)
}

export const LabelInput = ({ labelText, index, name, register, errors, ...props }: InputProps) => {
	const errorText = errors?.[name]?.message as string

	return (
		<div className="grow">
			<div className={clsx('relative overflow-visible rounded-md', props.className)}>
				<label
					htmlFor="name"
					className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3 transition-all duration-300">
					{labelText}
				</label>
				<input
					{...(register?.(name) ?? {})}
					type="text"
					id={index}
					{...props}
					className="w-full rounded font-normal pl-4 py-3 bg-transparent focus:ring-0 border focus:border-[#D3E3F1] border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md"
				/>
			</div>
			{errorText && <p className="text-xs text-red-500">{errorText}</p>}
		</div>
	)
}
