import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

interface MenuOptions {
	options: {
		name: string
		icon?: React.ElementType
		action: () => void
	}[]
}

export const EllipsisMenu = ({ options }: MenuOptions) => {
	return (
		<Menu as="div" className="relative inline-block text-left rounded-lg">
			{({ open }) => (
				<>
					<Menu.Button className="inline-flex w-full items-center space-x-4 text-slate-900">
						<div className="flex cursor-pointer items-center justify-center">
							<EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />
						</div>
					</Menu.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95">
						<Menu.Items className="absolute top-full w-56 origin-top-right scale-100 transform divide-y divide-gray-100 rounded-lg bg-white text-sm font-normal text-slate-900 opacity-100 shadow-md ring-1 ring-slate-900/5 focus:outline-none sm:-mr-3.5 right-0 -mr-0.5">
							<div className="truncate" role="none">
								{options.map(option => (
									<Menu.Item key={option.name}>
										<button
											onClick={option.action}
											className="group flex w-full items-center rounded-lg py-3 px-4 text-sm hover:bg-gray-100">
											{option?.icon && <option.icon className="w-5 h-5" />}
											<span className="mx-2">{option.name}</span>
										</button>
									</Menu.Item>
								))}
							</div>
						</Menu.Items>
					</Transition>
				</>
			)}
		</Menu>
	)
}
