import { useEffect, useState } from 'react'

import { AppLayout } from 'components/app/layout'
import { MobileNavigation } from 'components/app/mobile-navigation'
import { SearchResultCard } from 'components/cards/search-result-card'
import { Search } from 'components/search/search'
import { useSearchContext } from 'components/search/search-context'
import { Pagination } from 'flowbite-react'
import { useAppSelector } from 'hooks'
import { useTranslation } from 'react-i18next'
import categoryService from 'services/category-service'
import companyService from 'services/company-service'
import { getTKey } from 'utils/language'

export const SearchResult = () => {
	const { t } = useTranslation()
	const { searchResult } = useSearchContext()
	const tKey = getTKey('search')

	const auth = useAppSelector(state => state.auth)

	const [searchData, setSearchData] = useState(searchResult)
	const [categories, setCategories] = useState<Category[]>()
	const [type, setType] = useState<string>()
	const [currentPage, setCurrentPage] = useState(1)
	const onPageChange = (page: number) => setCurrentPage(page)
	const [companyLogos, setCompanyLogos] = useState<{
		[id: string]: { name: string; url: string }
	}>({})

	useEffect(() => {
		setSearchData(searchResult)
	}, [searchResult])

	useEffect(() => {
		categoryService.getAllCategories().then(res => setCategories(res))
	}, [])

	useEffect(() => {
		if (searchData) {
			const fetchLogos = async (data: SearchResult) => {
				const response = await companyService.getCompanyLogo(data._id)
				return { [data._id]: response[0] }
			}
			Promise.all(searchData.map(fetchLogos)).then(response => {
				setCompanyLogos(prevCompanyLogos => ({
					...prevCompanyLogos,
					...response.reduce((acc, curr) => ({ ...acc, ...curr }), {})
				}))
			})
		}
	}, [searchData])

	return (
		<AppLayout renderDashboardHeader={!!auth.accessToken} renderHeader={!auth.accessToken}>
			<div className="lg:px-[77px] min-h-[80vh] px-5 lg:py-8 flex flex-col gap-y-10 max-lg:pb-24">
				<div className="flex max-w-[1286px] w-full self-center flex-col lg:flex-row max-lg:gap-y-5 lg:gap-x-[30px] items-center">
					<Search
						type={type}
						currentPage={currentPage}
						suggestionClassName="translate-y-[70px]"
						className="border border-[#12325833] main-search-shadow max-lg:mt-3.5"
					/>
					<select
						className="text-center rounded-md lg:w-fit w-full border-primary max-lg:py-2"
						onChange={e => setType(e.target.value)}
						name="categories">
						<option value="">{t(tKey('allCategory'))}</option>
						{categories?.map(category => (
							<option key={category._id} value={category.label}>
								{category.label}
							</option>
						))}
					</select>
				</div>
				{searchData.map(data => (
					<SearchResultCard
						key={data._id}
						id={data._id}
						image={companyLogos[data._id]?.url}
						location={data.google_map}
						name={data.company_name}
						description={data.about_company}
						categories={data.company_type}
					/>
				))}

				{searchData.length > 10 && (
					<div className="flex overflow-x-auto justify-center lg:justify-end">
						<Pagination
							showIcons
							currentPage={currentPage}
							totalPages={10}
							onPageChange={onPageChange}
						/>
					</div>
				)}
			</div>
			<MobileNavigation />
		</AppLayout>
	)
}
