import { Link, useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { FacebookIcon, InstagramIcon, SignatureIcon, SMSIcon, WhiteLogoIcon } from 'assets/icons'
import { alphabets } from 'constants/constants'
import useIsMobile from 'hooks/useIsMobile'
interface FooterProps {
	className?: string
}

export const Footer = ({ className }: FooterProps) => {
	const navigate = useNavigate()
	const isMobile = useIsMobile()

	const navigateToEncyclopedia = () => {
		navigate('/fachrichtungen')
		window.scrollTo(0, 0)
	}

	return (
		<div
			className={clsx(
				'w-full bg-[#161C28] text-white px-5 md:px-[56px] pt-[48px] lg:pt-[64px]',
				className
			)}>
			<div className="flex justify-between items-center">
				<div className="flex gap-x-5 items-center max-lg:mb-12">
					<img src={WhiteLogoIcon} className="h-[32.62px] w-[200px] object-contain" />
				</div>
				<div className="flex gap-x-5 max-lg:hidden">
					<img
						src={SignatureIcon}
						className="rounded-md h-[60px] w-[178px] cursor-pointer object-contain"
					/>
					<img
						src={SMSIcon}
						className="rounded-md h-h-[60px] w-[178px] cursor-pointer object-contain"
					/>
				</div>
			</div>
			<div className="lg:border-b lg:border-[#A2B0BD] lg:py-[40px] lg:px-[60px] grid lg:grid-cols-4 grid-cols-2 lg:justify-items-center max-lg:justify-start md:grid-cols-2 gap-x-10 gap-y-8">
				<div className="flex flex-col gap-y-5 items-start lg:text-[15px] max-lg:text-sm font-normal leading-6">
					<h4 className="font-bold">Über Health Corner</h4>
					<ul className="flex flex-col gap-y-1 text-[#A2B0BD] cursor-pointer">
						<li
							onClick={() => navigate('/community_lexikon/65b241b32617d6983e0d1f46')}
							className="hover:underline">
							COVID-Test - Früherkennung
						</li>
						<li
							onClick={() => navigate('/community_lexikon/65b23feb2617d6983e0d1f0b')}
							className="hover:underline">
							PCR-Test
						</li>
						<li
							onClick={() => navigate('/community_lexikon/65b23db22617d6983e0d1ea4')}
							className="hover:underline">
							Videosprechstunde
						</li>
						<li className="hover:underline">Karriere</li>
						<li className="hover:underline">Presse</li>
						<li
							className="hover:underline"
							onClick={() => navigate(isMobile ? '/support' : '/?support=true')}>
							Hilfebereich
						</li>
						<li className="hover:underline" onClick={() => navigate('/community_lexikon')}>
							Gesundheitslexikon
						</li>
						<li className="hover:underline" onClick={() => navigate('/datenschutzzentrum')}>
							Datenschutzzentrum
						</li>
						<li className="hover:underline" onClick={() => navigate('/auth')}>
							Login / Registrieren
						</li>
					</ul>
				</div>
				<div className="flex flex-col gap-y-5 lg:text-[15px] max-lg:text-sm font-normal leading-6 items-start col-span-2">
					<h4 className="font-bold">Finden Sie Ihren Gesundheitsbetriebe / Fachperson</h4>
					<div className="grid grid-cols-2 gap-x-14">
						<ul className="flex flex-col gap-y-1 text-[#A2B0BD] cursor-pointer">
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Haut- und
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Geschlechtskrankheiten
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Orthopädie und Unfallchirurgie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Augenheilkunde / Ophthalmologie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Frauenheilkunde und Geburtshilfe
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Zahnmedizin
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Urologie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Radiologie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Allgemeinmedizin (Hausarzt)
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Neurologie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Innere Medizin und Kardiologie
							</li>
						</ul>
						<ul className="flex flex-col gap-y-1 text-[#A2B0BD] cursor-pointer">
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Physiotherapie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Hals-Nasen-Ohrenheilkunde (HNO)
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Gastroenterologie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Psychiatrie und Psychotherapie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Kinder- und Jugendmedizin
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Proktologie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Osteopathie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Innere Medizin und Rheumatologie
							</li>
							<li className="hover:underline" onClick={navigateToEncyclopedia}>
								Heilpraktiker
							</li>
						</ul>
					</div>
				</div>
				<div className="flex flex-col gap-y-5 lg:text-[15px] max-lg:text-sm items-start text-white">
					<h4 className="font-bold">Häufige Suchanfragen</h4>
					<ul className="flex flex-col gap-y-1 text-[#A2B0BD] cursor-pointer">
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Hautarzt / Dermatologe
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Orthopäde und Unfallchirurg
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Augenarzt / Ophthalmologe
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Frauenarzt / Gynäkologe
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Zahnarzt
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Urologe
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Radiologe
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Hausarzt / Allgemeinmediziner
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Neurologe
						</li>
						<li className="hover:underline" onClick={navigateToEncyclopedia}>
							Kardiologe
						</li>
					</ul>
				</div>
			</div>
			<div className="flex gap-x-5 lg:hidden max-lg:border-b max-lg:border-[#A2B0BD] max-lg:py-6">
				<img
					src={SignatureIcon}
					className="rounded-md lg:h-[60px] lg:w-[178px] h-10 w-36 shrink-0 cursor-pointer object-contain"
				/>
				<img
					src={SMSIcon}
					className="rounded-md lg:h-[60px] lg:w-[178px] h-10 w-36 shrink-0 cursor-pointer object-contain"
				/>
			</div>

			<div className="border-b border-[#A2B0BD] py-[20px] lg:py-[48px]">
				<div className="grid lg:grid-cols-2 grid-cols-1 max-lg:gap-y-5 max-lg:py-8 lg:text-[16px] max-lg:text-sm leading-6 font-normal items-center text-white cursor-pointer">
					<h4>Suche nach Gesundheitsbetriebe / Fachpersonen</h4>
					<div className="flex gap-x-4 flex-wrap justify-self-end">
						{alphabets.map(alphabet => (
							<p className="hover:underline" onClick={navigateToEncyclopedia} key={alphabet}>
								{alphabet}
							</p>
						))}
					</div>
				</div>
			</div>
			<div className="flex justify-between max-lg:flex-col-reverse max-lg:items-center max-lg:text-center leading-6 font-normal gap-y-3 items-center lg:py-[48px] py-[34px] flex-wrap">
				<p className="justify-items-center text-white lg:text-[14px] text-xs">
					Copyright © {new Date().getFullYear()} Health Corner, Alle Rechte vorbehalten.
				</p>
				<div className="flex gap-x-6 max-lg:py-2">
					<img src={FacebookIcon} className="h-[24px] w-[24px]" />
					<Link target="_blank" to={'https://www.instagram.com/healthcorner_schweiz/'}>
						<img src={InstagramIcon} className="h-[24px] w-[24px]" />
					</Link>
					<Link target="_blank" to={'https://www.youtube.com/@HealthCorner-schweiz'}>
						<svg
							className="w-[24px] h-[24px]"
							xmlns="http://www.w3.org/2000/svg"
							fill="white"
							viewBox="0 0 576 512">
							<path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
						</svg>
					</Link>
				</div>
			</div>
		</div>
	)
}
