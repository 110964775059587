import axios from 'services/axios'

const getAllArticles = (): Promise<Article[]> => {
	return axios.get(`https://backres.healthcorner.ch/api/articles`).then(response => response.data)
}

const getArticleById = (id: string): Promise<Article> => {
	return axios
		.get(`https://backres.healthcorner.ch/api/article/${id}`)
		.then(response => response.data)
}

const articleService = {
	getAllArticles,
	getArticleById
}

export default articleService
