import React, { InputHTMLAttributes, useState } from 'react'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import useIsMobile from 'hooks/useIsMobile'
import { getAppLang } from 'utils/language'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string
	onDateChange: (value: string) => void
	className?: string
}

export const InputDate = ({ value, label, className, placeholder, onDateChange }: InputProps) => {
	const isMobile = useIsMobile()
	const appLanguage = getAppLang()

	const [isFocus, setIsFocus] = useState(false)

	const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target

		if (value === '') {
			return onDateChange('')
		}
		const selectedDate = DateTime.fromISO(event.target.value)
			.setLocale(appLanguage)
			.toFormat('dd LLL yyyy')
		onDateChange(selectedDate)
	}
	return (
		<div>
			{label && (
				<label htmlFor="date" className="text-sm">
					{label}
				</label>
			)}
			<div className="relative flex items-center">
				<input
					type="date"
					id="date"
					onFocus={() => setIsFocus(true)}
					onBlur={() => setIsFocus(false)}
					onChange={handleDateChange}
					className={clsx(
						'py-1.5 opacity-0 absolute z-30 input-cursor-pointer',
						value ? 'w-36' : 'w-24',
						className
					)}
				/>
				<input
					type="text"
					disabled
					value={value}
					placeholder={placeholder}
					className={clsx(
						'border font-semibold text-primary placeholder:text-primary text-sm',
						isFocus ? 'border-[#06AB91]' : 'border-[#D3E3F1]',
						value ? 'w-36' : 'w-24',
						className
					)}
				/>
				<div className="absolute right-4 -translate-y-0.5">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width={isMobile ? '24' : '18'}
						height={isMobile ? '24' : '19'}
						viewBox="0 0 18 19"
						fill="none">
						<path
							d="M11.1635 14.1409C10.725 14.1409 10.351 13.986 10.0413 13.6764C9.73173 13.3668 9.57692 12.9928 9.57692 12.5543C9.57692 12.1159 9.73173 11.7418 10.0413 11.4322C10.351 11.1226 10.725 10.9678 11.1635 10.9678C11.6019 10.9678 11.976 11.1226 12.2856 11.4322C12.5952 11.7418 12.75 12.1159 12.75 12.5543C12.75 12.9928 12.5952 13.3668 12.2856 13.6764C11.976 13.986 11.6019 14.1409 11.1635 14.1409ZM4.21153 16.3909C3.86634 16.3909 3.57812 16.2752 3.34688 16.044C3.11563 15.8127 3 15.5245 3 15.1793V5.60239C3 5.2572 3.11563 4.96898 3.34688 4.73773C3.57812 4.50648 3.86634 4.39086 4.21153 4.39086H5.53845V2.71777H6.34614V4.39086H11.7115V2.71777H12.4616V4.39086H13.7885C14.1337 4.39086 14.4219 4.50648 14.6531 4.73773C14.8844 4.96898 15 5.2572 15 5.60239V15.1793C15 15.5245 14.8844 15.8127 14.6531 16.044C14.4219 16.2752 14.1337 16.3909 13.7885 16.3909H4.21153ZM4.21153 15.6409H13.7885C13.9038 15.6409 14.0096 15.5928 14.1058 15.4966C14.2019 15.4005 14.25 15.2947 14.25 15.1793V8.60239H3.75V15.1793C3.75 15.2947 3.79808 15.4005 3.89423 15.4966C3.99039 15.5928 4.09616 15.6409 4.21153 15.6409ZM3.75 7.85239H14.25V5.60239C14.25 5.48701 14.2019 5.38124 14.1058 5.28508C14.0096 5.18893 13.9038 5.14086 13.7885 5.14086H4.21153C4.09616 5.14086 3.99039 5.18893 3.89423 5.28508C3.79808 5.38124 3.75 5.48701 3.75 5.60239V7.85239Z"
							fill="#123258"
						/>
					</svg>
				</div>
			</div>
		</div>
	)
}
