import axios from 'axios'
import { apiHost } from 'utils/host'
import { indexedStorageDB } from 'utils/local-forage'

const register = (data: Auth & { phone: string }) => {
	return axios.post(apiHost + '/auth/client/signup', data).then(res => res.data)
}

const sendMobileVerification = (phonenumber: string, flag: boolean, password?: string) => {
	return axios
		.post(apiHost + '/auth/client/sendMobileVerify', { phonenumber, flag, password })
		.then(res => res.data)
}

const sendRegistrationEmail = (data: Partial<Auth> & { name: string; phone: string }) => {
	return axios.post(apiHost + '/auth/client/sendUserData', data).then(res => res.data)
}
const mobileVerification = (phonenumber: string, code: string) => {
	return axios
		.post(apiHost + '/auth/client/mobileVerify', { phonenumber, code })
		.then(res => res.data)
}

const forgotPassword = (email: string) => {
	return axios.post(apiHost + '/auth/client/sendpassword', { email }).then(res => res.data)
}

const resetPassword = (email: string, new_password: string, otp: string) => {
	return axios
		.post(apiHost + '/auth/client/verifypassword', { email, new_password, otp })
		.then(res => res.data)
}

const login = (username: string, password: string) => {
	return axios
		.post(apiHost + '/auth/client/signin', {
			username,
			password
		})
		.then(response => {
			return response.data
		})
}

const logout = () => {
	indexedStorageDB.clear()
	window.location.reload()
}

const authService = {
	register,
	login,
	forgotPassword,
	resetPassword,
	sendMobileVerification,
	sendRegistrationEmail,
	mobileVerification,
	logout
}

export default authService
