import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Spinner } from 'components/animations/spinner'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { useAppSelector } from 'hooks'
import articleService from 'services/articles-service'
import { getTKey } from 'utils/language'

export const Articles = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const tKey = getTKey('articles')

	const auth = useAppSelector(state => state.auth)

	const [isLoading, setIsLoading] = useState(true)
	const [articles, setArticles] = useState<Article[]>([])

	useEffect(() => {
		articleService
			.getAllArticles()
			.then(res => setArticles(res))
			.finally(() => setIsLoading(false))
	}, [])

	if (isLoading) {
		return (
			<AppLayout renderDashboardHeader={!!auth.accessToken} renderHeader={!auth.accessToken}>
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t('userDashboard.labels.pleaseWait')}
						</span>
					</div>
				</div>
			</AppLayout>
		)
	}

	return (
		<AppLayout renderDashboardHeader={!!auth.accessToken} renderHeader={!auth.accessToken}>
			<div
				style={{
					background:
						'linear-gradient(92deg, rgba(38, 182, 168, 0.20) 24.2%, rgba(127, 154, 178, 0.20) 102.92%)'
				}}
				className="flex justify-center items-center h-[178px]">
				<h1 className="text-2xl lg:text-[40px] font-domine text-center font-bold text-primary">
					{t(tKey('articles'))}
				</h1>
			</div>
			<div className="grid py-11 lg:py-14 mb-[280px] lg:grid lg:grid-cols-3 lg:gap-y-[270px] lg:justify-center grid-cols-1 lg:gap-x-[24px] lg:px-[136px] px-5 gap-y-[280px]">
				{articles?.map(article => (
					<div
						onClick={() => navigate(`/community_lexikon/${article._id}`)}
						key={article._id}
						className="relative cursor-pointer flex flex-col items-center">
						<img
							src={article.image}
							alt={article.title}
							className="w-[368px] h-[280px] object-cover rounded-xl"
						/>
						<div className="bg-white h-[340px] translate-y-10 rounded-xl w-[300px] shadow-md absolute inset-y-1/2 px-4 py-6 text-center flex flex-col justify-between gap-y-4">
							<div className="flex flex-col gap-y-2">
								<h3 className="text-primary font-bold text-xl line-clamp-2 overflow-hidden text-ellipsis">
									{article.title}
								</h3>
								<p className="text-secondary line-clamp-6 overflow-hidden text-ellipsis">
									{article.overviewText}
								</p>
							</div>
							<div className="flex flex-col gap-y-4">
								<div className="flex gap-x-2 text-sm justify-center">
									<button className="rounded-[100px] px-3 py-0.5 bg-[#26B6A629]">Symptome</button>
									<button className="rounded-[100px] px-3 py-0.5 bg-[#FFBAC466]">
										Behandlungen
									</button>
								</div>
								<div className="flex items-center gap-x-2 justify-center cursor-pointer">
									<button
										onClick={() => navigate(`/community_lexikon/${article._id}`)}
										className="text-primary text-xl font-semibold cursor-pointer">
										{t('home.articles.Readmore')}
									</button>
									<ArrowRightIcon className="h-6 w-6 stroke-primary" />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<Footer />
		</AppLayout>
	)
}
