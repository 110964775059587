import axios from 'services/axios'

const getAllCategories = (): Promise<Category[]> => {
	return axios.get('/all/types').then(response => response.data)
}

const getCompanyCategories = (id: string): Promise<CompanyCategory[]> => {
	return axios.get(`/categories?id_company=${id}`).then(response => response.data)
}

const categoryService = {
	getAllCategories,
	getCompanyCategories
}

export default categoryService
