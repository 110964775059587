import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = { visitReasonForm: {} as VisitReasonForm }

const appointmentSlice = createSlice({
	name: 'appointment',
	initialState,
	reducers: {
		saveVisitForm(state, action: PayloadAction<{ visitReasonForm: VisitReasonForm }>) {
			return action.payload
		}
	}
})

export const { saveVisitForm } = appointmentSlice.actions
export default appointmentSlice.reducer
