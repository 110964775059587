import axios from 'services/axios'

const getAllRelations = (): Promise<Relation[]> => {
	return axios.get('/relations').then(response => response.data)
}

const getRelationById = (id: string): Promise<Relation> => {
	return axios.get(`/relations/${id}`).then(response => response.data)
}

const createRelation = (data: Relation) => {
	return axios.post('/relations', data).then(response => response.data)
}

const updateRelation = (id: string, data: Relation) => {
	return axios.put(`/relations/${id}`, data).then(response => response.data)
}

const deleteRelation = (id: string) => {
	return axios.delete(`/relations/${id}`).then(response => response.data)
}

const relationService = {
	getRelationById,
	createRelation,
	deleteRelation,
	updateRelation,
	getAllRelations
}

export default relationService
