import { MagnifyingGlassIcon, MapPinIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { Button } from 'components/app/button'
import { Input } from 'components/inputs/input'
import { useSearchContext } from 'components/search/search-context'
import { useNavigate } from 'react-router-dom'
import categoryService from 'services/category-service'
import companyService from 'services/company-service'
import { getTKey } from 'utils/language'
import { SearchSuggestion } from './search-suggestion'

import 'styles/search.css'

interface SearchProps {
	type?: string
	currentPage?: number
	className?: string
	suggestionClassName?: string
}

export const Search = ({ type, currentPage, className, suggestionClassName }: SearchProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const tKey = getTKey('search')

	const { searchParams, storeSearchResult, storeSearchParams } = useSearchContext()

	const [name, setName] = useState(searchParams.name)
	const [country, setCountry] = useState(searchParams.country)
	const [categories, setCategories] = useState<Category[]>([])
	const [isFocus, setIsFocus] = useState(false)
	const [suggestions, setSuggestions] = useState({
		companies: [],
		companyTypes: [],
		services: []
	})

	const { ref } = usePlacesWidget({
		apiKey: 'AIzaSyCGXuH09-63Tj6CyFBy4LeGjWkm9LTiEn4',
		options: {
			types: ['(regions)'],
			componentRestrictions: { country: 'ch' }
		},
		onPlaceSelected: place => {
			setCountry(place?.formatted_address)
		}
	})

	const handleSearch = (event: React.FormEvent) => {
		event.preventDefault()
		companyService.searchCompanies(name, country, type, currentPage).then(result => {
			storeSearchParams(name, country)
			storeSearchResult(result)
			navigate('/search-results')
		})
	}

	useEffect(() => {
		if (currentPage) {
			companyService.searchCompanies(name, country, type, currentPage).then(result => {
				storeSearchResult(result)
				navigate('/search-results')
			})
		}
	}, [currentPage])

	const showSuggestions = Object.values(suggestions).some(s => s.length > 0)

	useEffect(() => {
		companyService.getSearchSuggestions(name).then(res =>
			setSuggestions({
				companies: res.companies?.companies,
				companyTypes: res.company_types?.company_types,
				services: res.services?.services
			})
		)
	}, [name])

	useEffect(() => {
		categoryService.getAllCategories().then(res => setCategories(res))
	}, [])

	return (
		<div className="relative flex flex-col grow gap-y-1.5 w-full">
			<form
				onSubmit={handleSearch}
				className={clsx(
					'grid lg:flex max-lg:mt-11 auto-cols-auto grid-flow-row max-lg:gap-y-2 z-10 lg:grid-flow-col bg-white rounded-lg w-full px-[12px] py-2',
					className
				)}>
				<div className="relative grow basis-1/2 flex items-center">
					<Input
						placeholder={t(tKey('term'))}
						autoComplete="off"
						onChange={event => setName(event.target.value)}
						name="name"
						value={name}
						onFocus={() => setIsFocus(true)}
						onBlur={() =>
							setTimeout(() => {
								setIsFocus(false)
							}, 400)
						}
						className="pl-8 border-0"
					/>
					<div className="absolute">
						<MagnifyingGlassIcon className="h-5 w-5 stroke-gray-800" />
					</div>
				</div>
				<div className="lg:hidden border border-[#D3E3F1]" />
				<div className="relative basis-1/2 grow flex items-center lg:py-1.5">
					<input
						ref={ref as any}
						value={country}
						autoComplete="new-password"
						onChange={event => setCountry(event.target.value)}
						placeholder={t(tKey('location'))}
						className="rounded-lg w-full focus:ring-0 focus:border-0 text-primary placeholder-[#7F9AB2] placeholder:text-sm lg:placeholder:text-base leading-5 p-3 max-lg:py-2 pl-10 border-none "
					/>

					<div className="lg:absolute z-40">
						<MapPinIcon className="h-5 w-5 z-50 lg:ml-3 stroke-gray-800" />
					</div>
				</div>
				<Button type="submit" className="max-lg:py-2 py-3 px-8" onClick={handleSearch}>
					{t(tKey('button'))}
				</Button>
			</form>
			{isFocus && showSuggestions && (
				<SearchSuggestion
					content={suggestions}
					onSelection={(value: string) => setName(value)}
					className={suggestionClassName}
				/>
			)}
		</div>
	)
}

export const DashboardSearch = ({ className }: SearchProps) => {
	const [name, setName] = useState('')
	const { storeSearchResult } = useSearchContext()
	const navigate = useNavigate()

	const handleSearch = (event: React.FormEvent) => {
		event.preventDefault()
		companyService.searchCompanies(name).then(result => {
			storeSearchResult(result)
			navigate('/search-results')
		})
	}

	return (
		<div className={clsx(className)}>
			<form onSubmit={handleSearch} className="relative flex items-center">
				<Input
					name="name"
					placeholder="Name, Speciality, Practice..."
					className="rounded-full search-shadow px-4 py-2"
					onChange={event => setName(event.target.value)}
				/>
				<MagnifyingGlassIcon className="absolute h-6 w-6 right-3 stroke-primary stroke-2" />
			</form>
		</div>
	)
}
