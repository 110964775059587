import {
	ArrowRightIcon,
	ChatBubbleOvalLeftIcon,
	CheckCircleIcon,
	XMarkIcon
} from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import {
	ActivityIcon,
	AnalyzeIcon,
	BabyIcon,
	CalendarIcon,
	ChatIcon,
	CommunityIcon,
	CurvedCornerIcon,
	DiscussionIcon,
	EyeBagIcon,
	FaceIcon,
	FrontScreenIcon,
	HypoAllergenicIcon,
	MagnifyingGlassIcon,
	MobileFrontScreenIcon,
	PlayButtonIcon,
	PrivacyIcon,
	QrCodeIcon,
	QuestionMarkIcon,
	ReminderIcon,
	RemoteIcon,
	ThumbnailIcon,
	ToothIcon,
	UltrasoundIcon,
	WhiteLogoIcon
} from 'assets/icons'
import { Button, SupportButton } from 'components/app/button'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { Search } from 'components/search/search'
import { Support } from 'components/support'
import { AuthOptions } from 'constants/constants'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import articleService from 'services/articles-service'
import { getTKey } from 'utils/language'

export const Home = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const tKey = getTKey('home')
	const [searchParams, setSearchParams] = useSearchParams()

	const supportRef = useRef<HTMLImageElement>(null)
	const auth = useAppSelector(state => state.auth)

	const [showSupport, setShowSupport] = useState(false)

	useEffect(() => {
		if (searchParams.get('support') && supportRef.current) return supportRef.current.click()
	}, [searchParams])

	return (
		<AppLayout renderHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
			<div className="relative lg:mx-[60px] flex flex-col-reverse max-lg:gap-y-12 lg:flex-row justify-between lg:h-[451px] max-lg:pb-[54px] bg-primary rounded-[20px] lg:pl-14 2xl:pl-52 xl:pl-24 items-center">
				<div className="flex flex-col max-lg:px-5 gap-y-3.5 lg:py-16 lg:gap-y-5 xl:gap-y-8 text-white h-full justify-center">
					<h5 className="text-tertiary max-lg:text-center font-bold text-lg lg:text-2xl">
						{t(tKey('heading'))}
					</h5>
					<h1 className="w-full text-2xl max-lg:text-center lg:text-4xl font-domine font-bold">
						{t(tKey('tagline'))}
					</h1>
					<Search suggestionClassName="translate-y-[100px]" />
					<div className="absolute bottom-0 left-0">
						<img src={CurvedCornerIcon} alt="corner" className="rounded-bl-[20px]" />
					</div>
				</div>
				<img
					src={FrontScreenIcon}
					alt="healthcorner"
					className="w-[523.44px] max-h-[451px] rounded-r-[20px] object-cover max-lg:hidden"
				/>
				<img
					src={MobileFrontScreenIcon}
					alt="healthcorner"
					className="object-cover pr-[21px] w-[475px] h-[273px] lg:hidden"
				/>
			</div>
			<Category />
			<Features />
			<Community />
			<Security />
			<AppAdvertisement />
			<Highlights />
			<Articles />
			<Footer />
			{!isMobile && !showSupport && (
				<SupportButton>
					<img ref={supportRef} onClick={() => setShowSupport(true)} src={QuestionMarkIcon} />
				</SupportButton>
			)}
			{showSupport && (
				<Support
					onClose={() => {
						setShowSupport(false)
						if (searchParams.has('support')) {
							searchParams.delete('support')
							setSearchParams(searchParams)
						}
					}}
				/>
			)}
		</AppLayout>
	)
}

const Category = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const tKey = getTKey('home.categories')

	const categories = [
		{ icon: UltrasoundIcon, heading: t(tKey('companyTypes.Pharmacy')) },
		{ icon: BabyIcon, heading: t(tKey('companyTypes.Gynecology')) },
		{ icon: EyeBagIcon, heading: t(tKey('companyTypes.Ophthalmologist')) },
		{ icon: ToothIcon, heading: t(tKey('companyTypes.Dentist')) },
		{ icon: HypoAllergenicIcon, heading: t(tKey('companyTypes.Pediatrician')) }
	]
	return (
		<div className="lg:px-16 2xl:px-52 px-5 xl:px-24 flex flex-col gap-y-14 py-10 lg:py-[100px] cursor-pointer">
			<h2 className="font-domine text-primary text-xl lg:text-3xl text-center font-bold">
				{t(tKey('mainHeading'))}
			</h2>
			<div className="flex overflow-x-auto md:justify-center gap-y-[14px] gap-x-[14px] lg:place-items-center">
				{categories.map(category => (
					<div
						key={category.heading}
						onClick={() => navigate('/fachrichtungen')}
						className="bg-[#F6F8FB] rounded-xl w-32 h-32 lg:w-56 lg:h-48 shrink-0 flex flex-col gap-y-2 items-center justify-center">
						<img
							src={category.icon}
							alt={category.heading}
							className="w-[24px] h-[24px] lg:w-[54px] lg:h-[54px]"
						/>
						<h4 className="text-main font-semibold lg:text-2xl text-[10px]">{category.heading}</h4>
					</div>
				))}
			</div>
			<Button onClick={() => navigate('/fachrichtungen')} className="w-fit mx-auto">
				{t(tKey('companyTypes.ExploreMore'))}
			</Button>
		</div>
	)
}

const Community = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const tKey = getTKey('home')
	const navigate = useNavigate()
	return (
		<div className="max-lg:px-5 px-16 2xl:px-52 xl:px-24 py-10 lg:py-[100px] max-lg:flex max-lg:flex-col max-lg:gap-y-9 lg:grid lg:grid-cols-2 grid-flow-col items-center">
			<div className="relative flex flex-col items-center lg:mr-[90.5px] ">
				<img
					src={CommunityIcon}
					alt="community"
					className="min-w-[300px] min-h-[218px] lg:min-w-[603px] lg:min-h-[439px] object-cover"
				/>
				<div className="absolute bottom-0 max-lg:-translate-x-16 translate-y-4 lg:translate-y-6 flex items-center gap-x-2 lg:gap-x-3.5 shadow-md bg-white rounded-[10px] py-3 px-4 lg:py-6 lg:pl-6 lg:pr-3.5">
					<CheckCircleIcon className="lg:h-6 lg:w-6 w-3 h-3 stroke-[#2B9b5B]" />
					<p className="text-[#191A15] text-[9px] lg:text-lg">{t(tKey('community.joiningText'))}</p>
				</div>
				<div className="absolute bg-white max-lg:w-[150px] p-1.5 px-2 lg:py-3 lg:px-5 top-2 lg:top-6 max-lg:translate-x-[100px] lg:-right-32 shadow-md rounded-[10px] flex gap-x-1.5 lg:gap-x-[16px] items-center">
					<img src={FaceIcon} className="max-lg:w-[28px] max-lg:h-[28px]" />
					<div className="flex flex-col gap-y-px lg:gap-y-3">
						<h5 className="text-[#191A15] whitespace-nowrap text-[7px] lg:text-[16px]">
							Max Huber
						</h5>
						<p className="text-[#A6A6A6] text-[6px] lg:text-xs">{t(tKey('community.expert'))}</p>
					</div>
					<div className="bg-[#74C9A9] p-1 lg:p-[10px] rounded-full ml-11 lg:ml-[18px]">
						{isMobile ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="11"
								height="11"
								viewBox="0 0 11 11"
								fill="none">
								<path
									d="M9.57141 5.00764C9.57284 5.5552 9.44491 6.09536 9.19804 6.58412C8.90532 7.1698 8.45533 7.66242 7.89846 8.0068C7.34159 8.35118 6.69983 8.53372 6.04508 8.53397C5.49752 8.5354 4.95736 8.40747 4.4686 8.1606L2.10388 8.94884L2.89212 6.58412C2.64525 6.09536 2.51732 5.5552 2.51874 5.00764C2.519 4.35289 2.70154 3.71113 3.04592 3.15426C3.3903 2.59739 3.88292 2.14739 4.4686 1.85468C4.95736 1.60781 5.49752 1.47988 6.04508 1.4813H6.25251C7.11723 1.52901 7.93397 1.89399 8.54635 2.50637C9.15872 3.11875 9.52371 3.93549 9.57141 4.80021V5.00764Z"
									stroke="white"
									strokeWidth="0.829726"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						) : (
							<ChatBubbleOvalLeftIcon className="w-[20px] h-[20px] stroke-white stroke-2" />
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-col px-5 lg:pl-14 max-lg:items-center justify-center h-full gap-y-10 bg-community bg-no-repeat bg-top">
				<div className="flex flex-col gap-y-3 max-lg:text-center">
					<h2 className="text-primary font-bold text-2xl lg:text-4xl">
						{t(tKey('community.heading'))}
					</h2>
					<p className="text-secondary">{t(tKey('community.description'))}</p>
					<p className="text-tertiary">Gesundheitslexikon & Community</p>
				</div>
				<h3 className="text-primary text-[20px] lg:text-2xl font-bold">
					{t(tKey('community.tagline'))}
				</h3>
				<Button onClick={() => navigate(`/community_lexikon`)} className="w-fit">
					{t('home.articles.Readmore')}
				</Button>
			</div>
		</div>
	)
}

const Features = () => {
	const { t } = useTranslation()
	const tKey = getTKey('home.features')
	const navigate = useNavigate()
	const features = [
		{
			image: MagnifyingGlassIcon,
			heading: t(tKey('bookAppointments.heading')),
			content: t(tKey('bookAppointments.content'))
		},
		{
			image: ActivityIcon,
			heading: t(tKey('automaticSMSReminders.heading')),
			content: t(tKey('automaticSMSReminders.content'))
		},
		{
			image: AnalyzeIcon,
			heading: t(tKey('healthCornerDashboard.heading')),
			content: t(tKey('healthCornerDashboard.content'))
		},
		{
			image: DiscussionIcon,
			heading: t(tKey('healthLexicon.heading')),
			content: t(tKey('healthLexicon.content'))
		}
	]
	return (
		<div className="py-10 lg:py-[100px] 2xl:px-52 lg:px-16 xl:px-24 px-5 bg-[#F5F7FA] flex flex-col gap-y-14">
			<div className="flex flex-col gap-y-4">
				<div className="flex flex-col gap-y-3">
					<h5 className="text-tertiary font-bold text-lg lg:text-2xl text-center">
						{t(tKey('mainHeading'))}
					</h5>
					<h2 className="text-primary font-domine text-2xl lg:text-4xl text-center font-bold">
						{t(tKey('tagline'))}
					</h2>
				</div>

				<p className="text-secondary text-center">
					{t(tKey('description'))}{' '}
					<span className="font-semibold"> {t(tKey('membershipText'))} </span>
				</p>
				<Button
					onClick={() => navigate(`/auth?tab=${AuthOptions.REGISTER}`)}
					className="w-fit mx-auto">
					{t(tKey('registerButton'))}
				</Button>
			</div>
			<div className="grid grid-rows-4 lg:grid-rows-2 grid-cols-1 lg:grid-cols-2 gap-9">
				{features.map(feature => (
					<div
						key={feature.heading}
						className="flex flex-col max-lg:gap-y-5 lg:flex-row lg:gap-x-5">
						<img
							src={feature.image}
							alt={feature.heading}
							className="lg:self-stretch lg:w-[114px] object-cover max-lg:object-contain max-lg:h-[100px] max-lg:self-start"
						/>
						<div className="flex flex-col gap-y-[8px]">
							<h3 className="text-primary font-bold text-lg lg:text-2xl">{feature.heading}</h3>
							<p
								className="text-secondary max-lg:text-sm line-clamp-4"
								dangerouslySetInnerHTML={{ __html: feature.content }}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

const Security = () => {
	const { t } = useTranslation()
	const tKey = getTKey('home')
	return (
		<div className="bg-[#F5F7FA] 2xl:px-52 pb-[100px] lg:px-16 xl:px-24 px-5 py-8 lg:pt-[100px] grid lg:grid-cols-2 grid-cols-1 max-lg:gap-y-8 lg:gap-x-10 justify-items-center items-center">
			<div className="flex flex-col gap-y-12 max-lg:items-center">
				<div className="flex flex-col gap-y-3 max-lg:items-center">
					<h5 className="text-tertiary font-bold text-lg lg:text-2xl">
						{t(tKey('security.heading'))}
					</h5>
					<h2 className="text-primary font-domine text-2xl lg:text-4xl font-bold max-lg:text-center">
						{t(tKey('security.tagline'))}
					</h2>
					<p className="text-secondary max-lg:text-sm max-lg:text-center">
						{t(tKey('security.description'))}
					</p>
				</div>
				<Link to="/datenschutzzentrum">
					<Button className="w-fit">{t(tKey('security.hostButton'))}</Button>
				</Link>
			</div>

			<img
				src={PrivacyIcon}
				alt="privacy"
				className="max-lg:min-w-[475px] lg:min-h-[347px] min-w-[643px] object-cover"
			/>
		</div>
	)
}

const AppAdvertisement = () => {
	const { t } = useTranslation()
	const tKey = getTKey('home')
	return (
		<div className="lg:pt-[60px] xl:px-24 lg:px-16">
			<div className="max-lg:hidden min-w-full bg-contain relative bg-appBanner bg-center bg-no-repeat xl:h-[450px] 2xl:h-[739px] h-[400px] w-full flex">
				<div className="basis-[40%]" />
				<div className="basis-[30%] text-center flex justify-center items-center">
					<div className="flex flex-col translate-y-10">
						<h3 className="text-[#13BAB4] 2xl:text-2xl text-xl whitespace-nowrap font-bold">
							{t(tKey('advertisement.heading'))}
						</h3>
						<h1
							dangerouslySetInnerHTML={{ __html: t(tKey('advertisement.yourApp')) }}
							className="font-bold text-white font-domine 2xl:text-[50px] text-[40px]"
						/>

						<h5 className="text-white 2xl:text-xl">{t(tKey('advertisement.download'))}</h5>
					</div>
				</div>
				<div className="basis-[30%] flex flex-col pr-8 items-center translate-y-10">
					<div className="relative self-end top-16 2xl:top-40">
						<img src={WhiteLogoIcon} className="2xl:w-56 w-40" />
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							viewBox="0 0 36 36"
							className="absolute max-2xl:w-[24px] top-0 -translate-y-3 -translate-x-1"
							fill="none">
							<circle cx="18" cy="18" r="18" fill="#26B6A6" fillOpacity="0.7" />
						</svg>
					</div>
					<div className="my-auto flex flex-col items-center gap-y-5">
						<img src={QrCodeIcon} className="w-24 h-24 2xl:w-36 2xl:h-36 border border-[#26B6A6]" />
						<div className="flex gap-x-5">
							<button className="w-40 2xl:w-44 2xl:h-20 gap-x-1 items-center justify-center rounded-xl border border-white h-16 flex  bg-black text-white ">
								<svg
									fill="#FFFFFF"
									width="34px"
									height="34px"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
									stroke="#FFFFFF">
									<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
									<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
									<g id="SVGRepo_iconCarrier">
										<path d="M17.564 13.862c-.413.916-.612 1.325-1.144 2.135-.742 1.13-1.79 2.538-3.087 2.55-1.152.01-1.448-.75-3.013-.741-1.564.008-1.89.755-3.043.744-1.297-.012-2.29-1.283-3.033-2.414-2.077-3.16-2.294-6.87-1.013-8.843.91-1.401 2.347-2.221 3.697-2.221 1.375 0 2.24.754 3.376.754 1.103 0 1.775-.756 3.365-.756 1.2 0 2.474.655 3.381 1.785-2.972 1.629-2.49 5.873.514 7.007zM12.463 3.808c.577-.742 1.016-1.788.857-2.858-.944.065-2.047.665-2.692 1.448-.584.71-1.067 1.763-.88 2.787 1.03.031 2.096-.584 2.715-1.377z"></path>
									</g>
								</svg>
								<div className="flex flex-col">
									<span className="text-xs">Download on the</span>
									<span className="font-semibold text-xl">App Store</span>
								</div>
							</button>
							<button className="w-40 2xl:h-20 2xl:w-44 gap-x-1 items-center justify-center rounded-xl border border-white h-16 flex bg-black text-white ">
								<svg
									width="34px"
									height="34px"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
									<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
									<g id="SVGRepo_iconCarrier">
										<mask
											id="mask0_87_8320"
											maskUnits="userSpaceOnUse"
											x="7"
											y="3"
											width="24"
											height="26">
											<path
												d="M30.0484 14.4004C31.3172 15.0986 31.3172 16.9014 30.0484 17.5996L9.75627 28.7659C8.52052 29.4459 7 28.5634 7 27.1663L7 4.83374C7 3.43657 8.52052 2.55415 9.75627 3.23415L30.0484 14.4004Z"
												fill="#C4C4C4"></path>
										</mask>
										<g mask="url(#mask0_87_8320)">
											<path
												d="M7.63473 28.5466L20.2923 15.8179L7.84319 3.29883C7.34653 3.61721 7 4.1669 7 4.8339V27.1664C7 27.7355 7.25223 28.2191 7.63473 28.5466Z"
												fill="url(#paint0_linear_87_8320)"></path>
											<path
												d="M30.048 14.4003C31.3169 15.0985 31.3169 16.9012 30.048 17.5994L24.9287 20.4165L20.292 15.8175L24.6923 11.4531L30.048 14.4003Z"
												fill="url(#paint1_linear_87_8320)"></path>
											<path
												d="M24.9292 20.4168L20.2924 15.8179L7.63477 28.5466C8.19139 29.0232 9.02389 29.1691 9.75635 28.766L24.9292 20.4168Z"
												fill="url(#paint2_linear_87_8320)"></path>
											<path
												d="M7.84277 3.29865L20.2919 15.8177L24.6922 11.4533L9.75583 3.23415C9.11003 2.87878 8.38646 2.95013 7.84277 3.29865Z"
												fill="url(#paint3_linear_87_8320)"></path>
										</g>
										<defs>
											<linearGradient
												id="paint0_linear_87_8320"
												x1="15.6769"
												y1="10.874"
												x2="7.07106"
												y2="19.5506"
												gradientUnits="userSpaceOnUse">
												<stop stopColor="#00C3FF"></stop>{' '}
												<stop offset="1" stopColor="#1BE2FA"></stop>{' '}
											</linearGradient>
											<linearGradient
												id="paint1_linear_87_8320"
												x1="20.292"
												y1="15.8176"
												x2="31.7381"
												y2="15.8176"
												gradientUnits="userSpaceOnUse">
												<stop stopColor="#FFCE00"></stop>{' '}
												<stop offset="1" stopColor="#FFEA00"></stop>{' '}
											</linearGradient>
											<linearGradient
												id="paint2_linear_87_8320"
												x1="7.36932"
												y1="30.1004"
												x2="22.595"
												y2="17.8937"
												gradientUnits="userSpaceOnUse">
												<stop stopColor="#DE2453"></stop>{' '}
												<stop offset="1" stopColor="#FE3944"></stop>{' '}
											</linearGradient>
											<linearGradient
												id="paint3_linear_87_8320"
												x1="8.10725"
												y1="1.90137"
												x2="22.5971"
												y2="13.7365"
												gradientUnits="userSpaceOnUse">
												<stop stopColor="#11D574"></stop>{' '}
												<stop offset="1" stopColor="#01F176"></stop>{' '}
											</linearGradient>
										</defs>
									</g>
								</svg>
								<div className="flex flex-col items-start">
									<span className="text-xs uppercase">Get it on</span>
									<span className="font-semibold text-xl">Google Play</span>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="lg:hidden bg-mobileAppBanner h-[850px] min-w-full bg-cover bg-no-repeat bg-center px-5 pt-10 sm:pt-24">
				<div className="flex flex-col gap-y-5 items-center">
					<div className="relative">
						<img src={WhiteLogoIcon} className="w-[200px]" />
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="32"
							height="32"
							viewBox="0 0 36 36"
							className="absolute top-0 -translate-y-3 -translate-x-1"
							fill="none">
							<circle cx="18" cy="18" r="18" fill="#26B6A6" fillOpacity="0.7" />
						</svg>
					</div>
					<h3 className="text-[#13BAB4] text-lg whitespace-nowrap font-bold">
						{t(tKey('advertisement.heading'))}
					</h3>
					<h1 className="font-bold text-white font-domine text-xl">
						{t(tKey('advertisement.mobileHeading'))}
					</h1>
					<h5 className="text-white">{t(tKey('advertisement.download'))}</h5>
				</div>
				<div className="flex flex-col gap-y-6 mt-10 items-center">
					<img src={QrCodeIcon} className="w-24 h-24 2xl:w-36 2xl:h-36 border border-[#26B6A6]" />
					<div className="flex gap-x-4">
						<button className="w-36 gap-x-1 items-center justify-center rounded-xl border border-white h-14 flex  bg-black text-white ">
							<svg
								fill="#FFFFFF"
								width="30px"
								height="30px"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
								stroke="#FFFFFF">
								<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
								<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
								<g id="SVGRepo_iconCarrier">
									<path d="M17.564 13.862c-.413.916-.612 1.325-1.144 2.135-.742 1.13-1.79 2.538-3.087 2.55-1.152.01-1.448-.75-3.013-.741-1.564.008-1.89.755-3.043.744-1.297-.012-2.29-1.283-3.033-2.414-2.077-3.16-2.294-6.87-1.013-8.843.91-1.401 2.347-2.221 3.697-2.221 1.375 0 2.24.754 3.376.754 1.103 0 1.775-.756 3.365-.756 1.2 0 2.474.655 3.381 1.785-2.972 1.629-2.49 5.873.514 7.007zM12.463 3.808c.577-.742 1.016-1.788.857-2.858-.944.065-2.047.665-2.692 1.448-.584.71-1.067 1.763-.88 2.787 1.03.031 2.096-.584 2.715-1.377z"></path>
								</g>
							</svg>
							<div className="flex flex-col">
								<span className="text-[10px]">Download on the</span>
								<span className="font-semibold text-lg">App Store</span>
							</div>
						</button>
						<button className="w-36 gap-x-1 items-center justify-center rounded-xl border border-white h-14 flex bg-black text-white ">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								aria-label="Google Play"
								role="img"
								viewBox="0 0 512 512"
								width="30px"
								height="30px"
								fill="#000000">
								<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
								<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
								<g id="SVGRepo_iconCarrier">
									<rect width="512" height="512" rx="15%" fill="black"></rect>{' '}
									<clipPath id="a">
										<path d="M465 239L169 69c-15-9-35 2-35 20v334c0 18 20 29 35 20l296-171c13-7 13-25 0-33z"></path>{' '}
									</clipPath>
									<g clipPath="url(#a)" transform="translate(-10 17) scale(.93324)">
										<path fill="#0e7" d="M20 53h400v355"></path>
										<path fill="#0df" d="M512 444H98V30"></path>
										<path fill="#f34" d="M399 181v322H76"></path>
										<path fill="#fd0" d="M324 256l180 180V76"></path>
									</g>
								</g>
							</svg>
							<div className="flex flex-col items-start">
								<span className="text-[10px] uppercase">Get it on</span>
								<span className="font-semibold text-lg">Google Play</span>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

const Highlights = () => {
	const { t } = useTranslation()
	const tKey = getTKey('home')
	const highlights = [
		{
			image: ChatIcon,
			heading: t(tKey('highlights.TeamRelief.heading')),
			content: t(tKey('highlights.TeamRelief.content'))
		},
		{
			image: ReminderIcon,
			heading: t(tKey('highlights.Efficiency.heading')),
			content: t(tKey('highlights.Efficiency.content'))
		},
		{
			image: CalendarIcon,
			heading: t(tKey('highlights.UI.heading')),
			content: t(tKey('highlights.UI.content'))
		},
		{
			image: RemoteIcon,
			heading: t(tKey('highlights.service.heading')),
			content: t(tKey('highlights.service.content'))
		}
	]

	const [playVideo, setPlayVideo] = useState(false)
	const videoRef = useRef<HTMLVideoElement>(null)

	return (
		<div className="max-lg:pb-10 max-lg:pt-16 lg:py-[100px] 2xl:px-52 xl:px-24 lg:px-16 px-5 flex flex-col gap-y-14">
			<div className=" relative grid lg:grid-cols-2 grid-cols-1 justify-items-center lg:gap-x-6 lg:grid-flow-col max-lg:gap-y-8">
				<div className="flex flex-col gap-y-8 max-lg:items-center">
					<div className="flex flex-col gap-y-3 max-lg:items-center ">
						<h5 className="text-tertiary font-bold lg:text-2xl text-lg max-lg:text-center">
							{t(tKey('highlights.heading'))}
						</h5>
						<h2 className="text-primary font-domine lg:text-4xl text-2xl font-bold max-lg:text-center">
							{t(tKey('highlights.tagline'))}
						</h2>
						<p
							dangerouslySetInnerHTML={{ __html: t(tKey('highlights.description')) }}
							className="text-secondary text-base max-lg:text-center"
						/>
					</div>
					<Link to={'https://healthcorner.pro'} target="_blank">
						<Button className="w-fit">{t(tKey('highlights.Button'))}</Button>
					</Link>
				</div>
				{playVideo ? (
					<>
						<div className="max-lg:hidden fixed inset-0 bg-black bg-opacity-70 z-30"></div>
						<video
							ref={videoRef}
							className="absolute max-lg:hidden z-40 w-11/12 rounded-xl"
							controls>
							<source src={'assets/demo.mp4'} type="video/mp4" />
						</video>
						<XMarkIcon
							style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
							onClick={() => setPlayVideo(false)}
							className="absolute h-7 w-7 stroke-white z-50  max-lg:hidden right-20 mt-5 cursor-pointer"
						/>
						<div className="relative lg:hidden">
							<video ref={videoRef} className="lg:hidden z-40 w-full rounded-xl" controls>
								<source src={'assets/demo.mp4'} type="video/mp4" />
							</video>
							<XMarkIcon
								style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
								onClick={() => setPlayVideo(false)}
								className="absolute h-5 w-5 right-4 top-3 stroke-white z-50 lg:hidden cursor-pointer"
							/>
						</div>
					</>
				) : (
					<div
						onClick={() => setPlayVideo(true)}
						className="relative rounded-md flex items-center justify-center cursor-pointer">
						<img src={ThumbnailIcon} alt="thumbnail" className="h-full object-cover" />
						<div className="absolute rounded-full">
							<img src={PlayButtonIcon} className="h-20 w-20" />
						</div>
					</div>
				)}
			</div>
			<div className="grid lg:grid-cols-4 grid-cols-1 max-lg:gap-y-6 lg:gap-x-[25px]">
				{highlights.map(highlight => (
					<div key={highlight.heading} className="flex flex-col gap-y-6">
						<img
							src={highlight.image}
							alt={highlight.heading}
							className="h-[40px] object-contain pl-5 self-start"
						/>
						<h3 className="text-primary whitespace-nowrap font-bold text-xl border-l pl-5 border-[#26B6A6]">
							{highlight.heading}
						</h3>
						<p className="text-secondary pl-5">{highlight.content}</p>
					</div>
				))}
			</div>
		</div>
	)
}

const Articles = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const tKey = getTKey('home')

	const [articles, setArticles] = useState<Article[]>([])

	useEffect(() => {
		articleService.getAllArticles().then(response => setArticles(response))
	}, [])

	return (
		<div className="pt-10 lg:pt-[100px] pb-[300px] flex flex-col gap-y-14 bg-[#F5F7FA]">
			<h2 className="text-primary font-domine text-4xl font-bold text-center">
				{t(tKey('articles.heading'))}
			</h2>
			<div className="grid lg:flex lg:justify-center grid-cols-1 lg:gap-y-5 lg:gap-x-[24px] lg:px-36 px-5 gap-y-[280px]">
				{articles.slice(0, 3).map(article => (
					<div key={article._id} className="relative flex flex-col items-center">
						<img
							src={article.image}
							alt={article.title}
							className="w-[368px] h-[280px] object-cover rounded-xl"
						/>
						<div className="bg-white h-[340px] translate-y-10 rounded-xl w-[300px] shadow-md absolute inset-y-1/2 px-4 py-6 text-center flex flex-col justify-between gap-y-4">
							<div className="flex flex-col gap-y-2">
								<h3 className="text-primary font-bold text-xl line-clamp-2 overflow-hidden text-ellipsis">
									{article.title}
								</h3>
								<p className="text-secondary line-clamp-6 overflow-hidden text-ellipsis">
									{article.overviewText}
								</p>
							</div>
							<div className="flex flex-col gap-y-4">
								<div className="flex gap-x-2 text-sm justify-center">
									<button className="rounded-[100px] px-3 py-0.5 bg-[#26B6A629]">Symptome</button>
									<button className="rounded-[100px] px-3 py-0.5 bg-[#FFBAC466]">
										Behandlungen
									</button>
								</div>
								<div className="flex items-center gap-x-2 justify-center cursor-pointer">
									<button
										onClick={() => navigate(`/community_lexikon`)}
										className="text-primary text-xl font-semibold cursor-pointer">
										{t(tKey('articles.Readmore'))}
									</button>
									<ArrowRightIcon className="h-6 w-6 stroke-primary" />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
