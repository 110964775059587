import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/solid'
import { Fragment } from 'react'

import clsx from 'clsx'

interface MenuItemProps {
	options: string[]
	setActive: (value: string) => void
	selected: string
}

export const MenuDropdown = ({ options, setActive, selected }: MenuItemProps) => {
	return (
		<Menu as="div" className="relative rounded-xl inline-block text-left">
			{({ open }) => (
				<>
					<Menu.Button
						className={clsx(
							'flex gap-x-2.5 border focus:border-[#06AB91] items-center font-semibold text-sm rounded-full px-4 py-1.5 w-full focus:outline-none',
							{
								'bg-[#06AB910F] border-[#06AB91]': open
							}
						)}>
						<CheckIcon className="h-4 w-4 fill-[#06AB91]" />
						<span className="whitespace-nowrap text-primary">{selected}</span>
						<ChevronDownIcon className="h-4 w-4 text-primary" />
					</Menu.Button>
					{open && (
						<Transition
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Menu.Items
								className={clsx(
									'absolute w-max text-sm z-10 mt-1 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none left-0'
								)}
								static>
								<div className="px-1 py-1">
									{options.map(option => (
										<Menu.Item key={option}>
											<button
												className={clsx(
													selected === option
														? 'text-primary font-semibold'
														: 'text-primary font-semibold',
													'group whitespace-nowrap grow hover:bg-[#06AB910F] flex w-full text-left items-center rounded-md px-6 py-3'
												)}
												onClick={() => setActive(option)}>
												{option}
											</button>
										</Menu.Item>
									))}
								</div>
							</Menu.Items>
						</Transition>
					)}
				</>
			)}
		</Menu>
	)
}
